import { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { ROUTES } from '../../services/navigation/navigation.routes';
import { useAuthContext } from '../../context/AuthContext';

export const Portal = ({ children, el = 'div' }) => {
  const containerRef = useRef(document.createElement(el));
  const container = containerRef.current;
  const pathName = window.location.pathname || '';
  const pathSegment = `/${pathName.split('/')[1]}`;
  const { userLoggedIn } = useAuthContext();

  const urls = [
    ROUTES.root,
    ROUTES.theme,
    ROUTES.justAdded,
    ROUTES.mostWatched,
    ROUTES.originals,
    ROUTES.trentonducatioriginals,
    ROUTES.stars,
    ROUTES.studios,
    ROUTES.search,
    ROUTES.series,
    ROUTES.director,
  ];

  const allowedUrls = urls.map((route) => {
    if (route === '/') return null;
    return route.startsWith('/') ? route : `/${route}`;
  });

  const shouldCreatePortal = allowedUrls.includes(pathSegment);

  useEffect(() => {
    const mb_navBtns = document.getElementById('secondaryNavigation'); // movies/scenes buttons
    const mb_userBtns = document.querySelector('.MobileBottomUserButtons'); // login/logout buttons
    const mainContent = document.querySelector('.MainContent');

    if (container && mb_navBtns && shouldCreatePortal) {
      try {
        container.classList.add('SecondaryActions');
        mb_navBtns.appendChild(container);
        mainContent.style.marginBottom = mb_userBtns.offsetHeight + 'px';
      } catch (err) {
        console.error(err);
      }
    }

    return () => {
      if (shouldCreatePortal) {
        try {
          mb_navBtns.removeChild(container);
          mainContent.style.marginBottom = mb_userBtns.offsetHeight + 'px';
        } catch (err) {
          console.error(err);
        }
      }
    };
  }, [container, shouldCreatePortal, userLoggedIn]);

  if (shouldCreatePortal) {
    return createPortal(children, container);
  }

  return null;
};
