import React from 'react';
import { Link } from '@reach/router';

import { ROUTES } from '../../../services/navigation/navigation.routes';
import { useHtmlContentContext } from '../../../context/HtmlContentContext.js';
import RenderHtmlContent from '../../../components/RenderHtmlContent/RenderHtmlContent.js';

const AvsCancel = () => {
  const { htmlContent } = useHtmlContentContext();

  return (
    <>
      <div className="Content">
        <div className="Heading">AGE VERIFICATION CANCEL</div>

        <p>
          If you canceled by accident. Please{' '}
          <Link className="Link" to={`/${ROUTES.ageVerificationLanding}`}>
            click here to try again
          </Link>
          .
        </p>
      </div>
      <RenderHtmlContent htmlContent={htmlContent} dataLabel="support_service" />
    </>
  );
};

AvsCancel.displayName = 'AvsCancel';

export default AvsCancel;
