import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Link } from '@reach/router';
import { isWeb } from '../../../../services/util-service/util.service';
import { ModalController } from '../../../../controllers/modal-controller/modal.controller';
import ResetPassword from '../../../../components/MemberPWReminder/ResetPassword.js';

import './MyAccountUserInfos.scss';

class MyAccountUserInfos extends Component {
  displayForgotPasswordDialog = () => {
    const modal = <ResetPassword />;
    ModalController.showModal(modal);
  };

  render() {
    const { email, t } = this.props;

    return (
      <div className="MyAccountUserInfos">
        <div className="UserEmail">{email}</div>
        {isWeb() && <div className="Separator" />}
        <div className="Options">
          <div className="UserAccountLink" onClick={() => this.displayForgotPasswordDialog()}>
            {t('MyAccountUserInfos.resetPassword')}
          </div>
          <div className="Separator" />
          <Link to="/help" className="UserAccountLink">
            {t('MyAccountUserInfos.customerService')}
          </Link>
        </div>
      </div>
    );
  }
}

MyAccountUserInfos.propTypes = {
  email: PropTypes.string,
};

export default withTranslation()(MyAccountUserInfos);
