import React from 'react';

import { isWeb } from '../../../services/util-service/util.service';
import BannerSetViewFunc from '../../../components/BannerSetView/BannerSetViewFunc';
import RenderHtmlContent from '../../../components/RenderHtmlContent/RenderHtmlContent';
import { useHtmlContentContext } from '../../../context/HtmlContentContext';
import { ROUTES } from '../../../services/navigation/navigation.routes';

export default function UserInfoPages(props) {
  const { path } = props;
  const { htmlContent } = useHtmlContentContext();

  const renderBanner = (type) => {
    if (ROUTES.help === path && isWeb()) {
      return (
        <BannerSetViewFunc bannerName={type} />
      )
    }
    return null;
  };

  return (
    <>
      {renderBanner('promo')}
      <RenderHtmlContent htmlContent={htmlContent} dataLabel={path} />
      <RenderHtmlContent htmlContent={htmlContent} dataLabel="support_service" />
      {renderBanner('tour')}
    </>
  )
};
