import React from 'react';
import { Link } from '@reach/router';

import WeekendsPage from './WeekendsPage';
import Mask4MaskPage from './Mask4MaskPage';
import MissPineapple2021Page from './MissPineapple2021Page';
import MrMan2020Page from './MrMan2020Page';
import Pride2020Page from './Pride2020Page';
import Party20th from './Party20thPage';
import { ROUTES } from '../../../services/navigation/navigation.routes';
import { isWeb } from '../../../services/util-service/util.service';
import BannerSetViewFunc from '../../../components/BannerSetView/BannerSetViewFunc';

export default function WeekendMain(props) {
  const { path } = props;

  const renderContent = () => {
    let view = null;

    switch (path) {
      case ROUTES.weekends:
        view = <WeekendsPage />;
        break;
      case ROUTES.mask4mask:
        view = <Mask4MaskPage />;
        break;
      case ROUTES.misspineapple2021:
        view = <MissPineapple2021Page />;
        break;
      case ROUTES.mrman2020:
        view = <MrMan2020Page />;
        break;
      case ROUTES.party20th:
        view = <Party20th />;
        break;
      case ROUTES.pride2020:
        view = <Pride2020Page />;
        break;
      default:
        view = null;
    }

    return view;
  };

  const renderHeader = () => {
    return (
      <div style={{
        maxWidth: '80%',
        margin: '1em auto'
      }}>
        <img
          src="https://spotlight.nakedcdn.com/nakedsword/img/ns_play/ns-weekends-header.svg"
          alt="Nakedsword Weekends"
          style={{ maxWidth: '100%' }}
        />
        <div style={{ textAlign: 'center', fontSize: '86%' }}>
          Special Events, Live Shows, Free Scenes & Movies and more from NakedSword, "The Netflix of Gay Porn." Enjoy
          complimentary access to some of our most popular hardcore scenes and movies, live events and model features,
          absolutely FREE!
        </div>
      </div>
    )
  };

  const renderPosters = () => {
    const data = [{
      url: '/pride2020',
      src: 'https://spotlight.nakedcdn.com/nakedsword/img/ns_play/prev-pride-boxcover.jpg',
      alt: 'Pride Stories',
      text: 'pride stories'
    }, {
      url: '/mrman2020',
      src: 'https://spotlight.nakedcdn.com/nakedsword/img/ns_play/top20nudescenes-mrman.jpg',
      alt: 'mr. man top 20',
      text: 'mr. man top 20'
    }, {
      url: '/party20th',
      src: 'https://spotlight.nakedcdn.com/nakedsword/img/ns_play/prev-20thparty-FSP052_PCC_DVD.jpg',
      alt: 'nakedsword 20 party',
      text: 'nakedsword 20 party'
    }, {
      url: '/mask4mask',
      src: 'https://spotlight.nakedcdn.com/nakedsword/img/ns_play/prev-mask4mask-boxcover.jpg',
      alt: 'mask4mask',
      text: '#mask4mask'
    }, {
      url: '/misspineapple2021',
      src: 'https://spotlight.nakedcdn.com/nakedsword/img/ns_play/prev-misspineapple-2021.jpg',
      alt: 'miss pineapple',
      text: 'miss pineapple'
    }];

    return (
      <>
        <h4 style={{
          fontSize: '1.25em',
          textAlign: 'center',
          color: 'var(--secondary)',
          letterSpacing: '10px'
        }}>
          COMPLIMENTARY TITLES NOW PLAYING
        </h4>

        <div style={{
          textAlign: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '.5em',
          textTransform: 'uppercase',
          margin: '0 0 1em'
        }}>
          {data.map(item => {
            const { url, src, alt, text } = item;

            return (
              <Link to={url} key={url} style={{ flex: isWeb() ? '1' : '1 1 100%' }}>
                <img src={src} alt={alt} style={{ maxWidth: '100%', width: '100%' }} />
                <br />
                <span style={{
                  fontSize: '110%',
                  fontWeight: 600,
                  color: 'var(--secondary)'
                }}>{text}</span>
                <br />
                <span>watch free</span>
              </Link>
            )
          })}
        </div>
      </>
    )
  };

  return (
    <div style={{ maxWidth: isWeb() ? '90%' : '95%', margin: 'auto', textAlign: 'center' }}>
      {renderHeader()}
      {renderContent()}
      {renderPosters()}
      <BannerSetViewFunc bannerName="weekends" />
    </div>
  );
};
