import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import { createRequestInterceptor, createResponseInterceptor } from './axios-interceptor';
import { env } from './env';

axios.defaults.baseURL = env.REACT_APP_URL;
createRequestInterceptor();
createResponseInterceptor();

function initializeReactApp() {
  ReactDOM.render(<App />, document.getElementById('root'));
}

//React App First
initializeReactApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
