import React, { useEffect, useRef, useState } from 'react';
import { Link, navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { FullPlayer, VideoPlayController } from '@falconstudios/ns-player';
import ImageLoadingSkeleton from '../ImageLoadingSkeleton/ImageLoadingSkeleton';
import ImagesLightBox from '../ImagesLighbox/ImagesLighbox';
import PlaylistsPlayerPopup from '../PlaylistComponents/PlaylistsPlayerPopup/PlaylistsPlayerPopup';
import MovieSceneDetails from '../MovieSceneDetails/MovieSceneDetails';
import MovieDownloads from './MovieDownloads/MovieDownloads';

import { getMovieDetailsPageRoute } from '../../services/navigation/navigation.service.routes';
import {
  getVideoDownloadInfo,
  getVideoDownloadUrl,
  getVideoStills,
  getVideoStream,
} from '../../services/streaming-service/streaming.service';
import { getMoviePlayerConfig } from '../../services/player/player.service';
import {
  addToFavorites,
  addToViewingHistory,
  isInFavorites,
  removeFromFavorites,
} from '../../services/my-account-service/my-account.service';
import {
  displayInfoNotification,
  displayErrorNotification,
} from '../../services/notification-service/notification.service';
import { PREVIEWS_MAX_COUNT, getCookie, isWeb, setBrowserCookie } from '../../services/util-service/util.service';
import { convertSecondsToTime, convertRunTimeToSeconds } from '../../services/util-service/date.service';
import { getMovieDetails } from '../../services/movies/movies.service';

import { handleGallery } from '../../services/gallery-service/gallery.service';
import { playFilmIcon } from '../../assets/icons/icons';
import { getVideoAds } from '../../services/ads-service/ads.service';
import { SignInController } from '../../controllers/sign-in-controller/sign-in.controller';
import { getUserLocalData } from '../../services/users-service/users.service';
import { showApplicationStart, showEntityDetailsPage } from '../../services/navigation/navigation.service';
import { ROUTES } from '../../services/navigation/navigation.routes';
import { useAuthContext } from '../../context/AuthContext';
import { useWLconfigContext } from '../../context/WLConfigContext';
import { onEventGtag, useGtag } from '../../hook/useGtag';

import './BoxCoverPlayerDetailsComponent.scss';

const BoxCoverPlayerDetailsComponent = (props) => {
  const path = window.location.pathname || '';
  const pathSegments = path.split('/');
  const lastPathSegment = pathSegments[pathSegments.length - 1];
  const sceneIndexRef = useRef(); // on route change useEffect is triggered
  const sceneIndex = parseInt(lastPathSegment) || sceneIndexRef.current;
  const { userLoggedIn } = useAuthContext();
  const { t } = useTranslation();
  const pidRef = useRef(); // player id
  const isHeartIconFilledRef = useRef(false);
  const playlistsPlayerPopupRef = useRef(null);
  const firstMounthRef = useRef(false);
  const { memberid = 0 } = getUserLocalData();
  const {
    wl_config: { preview_max_count: preview_mc, preview_time_period: preview_tp } = {},
    wl_playerConfig: { progressBarColors = {} } = {}
  } = useWLconfigContext();

  const initialState = {
    ads: [],
    allPhotos: [],
    title: '',
    description: '',
    gallery: [],
    images: [],
    openGallery: false,
    stars: [],
    playerConfiguration: {},
    categories: [],
    directors: [],
    studios: [],
    videos: [],
    runTime: '',
    movieRunTime: '',
    scenes: [],
    activeScene: {},
    downloadLoading: false,
    downloadOptions: [],
    is_downloadable: false,
    showDownloadIcon: false,
    isError: false,
    loading: true,
    showPlaylistsPlayerPopup: false,
    movieId: '',
    sceneId: '',
    previewMaxCountOverlay: false,
  };

  const [state, setState] = useState(initialState);

  const {
    ads = [],
    allPhotos = [],
    title = '',
    description = '',
    gallery = [],
    images = [],
    openGallery,
    stars = [],
    categories = [],
    directors = [],
    studios = [],
    videos = [],
    playerConfiguration = {},
    runTime = '',
    downloadLoading,
    downloadOptions = [],
    showDownloadIcon,
    isError,
    loading,
    showPlaylistsPlayerPopup,
    previewMaxCountOverlay,
  } = state;

  useEffect(() => {
    setState(initialState);

    // for MovieDetailsPage.js
    const updateMovieDetailsPage = (scenes, recommendations, loading, error) => {
      if (props.updateMovieDetailsPage) {
        props.updateMovieDetailsPage((prevState) => ({
          movie: {
            ...prevState.movie,
            scenes,
            recommendations,
            loading,
            error,
          },
        }));
      }
    };

    // get movie/scene/episode/series data
    const getVideoData = (response = {}) => {
      let {
        ads = {},
        movieId: responseMovieId,
        title,
        titleNs,
        description,
        descriptionNs,
        runTime,
        is_downloadable,
        categories = [],
        gallery = [],
        images = [],
        directors = [],
        studios = [],
        scenes = [],
        stars = [],
        videos = [],
        recommendations = [],
      } = response;

      // find active scene from details page or some other page
      sceneIndexRef.current = sceneIndex;
      const activeScene =
        scenes.find((item) => item.index === sceneIndex) || scenes.find((item) => item.id === +props.sceneId) || {};

      const getNatsAndPropertiesAds = () => {
        const adsObj = ads.natsAds ? ads.natsAds?.[0] : ads.propertiesAds?.[0];
        if (adsObj) {
          if (adsObj.selectedSection === 'scene' && Object.keys(activeScene).length !== 0) {
            return [adsObj];
          } else if (adsObj.selectedSection === 'movie' && Object.keys(activeScene).length === 0) {
            return [adsObj];
          }
        }
        return [];
      };

      const currentVideoAds = ads.movieAds || getNatsAndPropertiesAds();
      const sortedScenesByIndex = scenes.sort(({ index }, { index: index2 }) => index - index2);
      const videoName = titleNs ? titleNs : title;
      const videoDescription = descriptionNs ? descriptionNs : description;
      const playerConfiguration = getMoviePlayerConfig(responseMovieId, studios) || {};

      const videoSrc = [
        {
          sources: [{ src: videos[0]?.url?.hls || '' }],
          poster: scenes[0]?.cover_images?.[0]?.url || '',
        },
      ];

      const videoData = {
        ads: currentVideoAds,
        movieId: responseMovieId,
        sceneId: activeScene.id,
        title: videoName,
        description: videoDescription,
        runTime,
        movieRunTime: runTime,
        is_downloadable,
        cover_images: [],
        playerConfiguration,
        categories,
        gallery,
        images,
        directors,
        studios,
        scenes: sortedScenesByIndex,
        stars,
        videos: videoSrc,
        recommendations,
        activeScene,
        isError: false, // changing videos in playlist too fast triggers abortControler and set isError=true, so set it false here
      };

      let movieStartTime = undefined;
      let movieDuration = undefined;

      if (userLoggedIn && videoData.activeScene && Object.keys(videoData.activeScene).length !== 0) {
        movieStartTime = videoData.activeScene.startTimeSeconds;
        movieDuration = videoData.activeScene.endTimeSeconds - videoData.activeScene.startTimeSeconds;
      } else if (!userLoggedIn) {
        movieStartTime = Math.floor((convertRunTimeToSeconds(runTime) * 70) / 100);
        movieDuration = 45;
      }

      // video stream filters
      const streamFilters = {
        movie_id: videoData.movieId,
        scenes_id: undefined,
        start_time: movieStartTime,
        duration: movieDuration,
        isPreview: userLoggedIn ? undefined : true,
      };
      // video stills filters
      const stillsFilters = {
        movie_id: videoData.movieId,
        start_time: undefined,
        duration: undefined,
      };

      // if it's a scene
      if (videoData.activeScene && Object.keys(videoData.activeScene).length !== 0) {
        const {
          id: responseSceneId,
          startTimeSeconds,
          endTimeSeconds,
          cover_images = [],
          is_downloadable,
          gallery = [],
          stars = [],
          videos = [],
          streaming_movie = {},
        } = videoData.activeScene;

        const sceneSrc = [
          {
            sources: [{ src: videos[0]?.url?.hls || '' }],
            poster: cover_images[0]?.url || '',
          },
        ];

        const sceneDuration = endTimeSeconds - startTimeSeconds;
        let sceneStartTime = startTimeSeconds + Math.floor((sceneDuration * 70) / 100);

        if (userLoggedIn) {
          sceneStartTime = activeScene.startTimeSeconds;
        }

        // scene stream filters
        streamFilters.scenes_id = responseSceneId;
        streamFilters.start_time = sceneStartTime;

        // scene stills filters
        stillsFilters.start_time = startTimeSeconds;
        stillsFilters.duration = sceneDuration;
        stillsFilters.movie_id = streaming_movie.id;

        videoData.sceneId = responseSceneId;
        videoData.runTime = `00:${convertSecondsToTime(sceneDuration)}`;
        videoData.is_downloadable = is_downloadable;
        videoData.gallery = gallery;
        videoData.description = streaming_movie.descriptionNs
          ? streaming_movie.descriptionNs
          : streaming_movie.description;

        const scenesAds = ads.sceneAds || [];
        const singleSceneAds = scenesAds.find((item) => +item?.sceneId === +responseSceneId)?.ads;
        videoData.ads = singleSceneAds || getNatsAndPropertiesAds();

        videoData.stars = stars;
        videoData.videos = sceneSrc;
        videoData.cover_images = cover_images;
      }

      // if user is logged in and has option to download (from nats), get downloads
      if (userLoggedIn) {
        const { downloads_allowed = 0 } = getUserLocalData();

        if (downloads_allowed === 1) {
          getVideoDownloadInfo(videoData.movieId)
            .then((resp = {}) => {
              const downloadInfo = resp.data?.data || [];

              const downloadOptions = downloadInfo.map((item) => {
                if (videoData.sceneId) {
                  let sceneFileSize = item.filesize;
                  const sceneDuration = convertRunTimeToSeconds(videoData.runTime);
                  const movieDuration = convertRunTimeToSeconds(videoData.movieRunTime);
                  sceneFileSize = Math.round((sceneFileSize * sceneDuration) / movieDuration);
                  return { ...item, filesize: sceneFileSize };
                }
                return item || [];
              });

              videoData.downloadOptions = downloadOptions?.sort((a, b) => b.bitrate - a.bitrate);
              videoData.showDownloadIcon = videoData.is_downloadable;
            })
            .catch((err) => console.log(err));
        }
      }

      return { videoData, streamFilters, stillsFilters };
    };

    const fetchVideoStream = (movieId, sceneId, start_time, duration, isPreview, smoothStreaming) => {
      const params = { movieId, sceneId, duration, smoothStreaming };

      if (window.location.pathname !== `/${ROUTES.diag}`) {
        params.start_time = start_time;
        params.isPreview = isPreview;
      }

      return getVideoStream(params, userLoggedIn);
    };

    const fetchVideoStills = (movieId, startTime, duration) => {
      return getVideoStills(movieId, startTime, duration);
    };

    const fetchVideoStreamAndStils = ({ videoData, streamFilters, stillsFilters }) => {
      // get video stream and stils from AEBN
      const streamAndStillsPromise = [
        fetchVideoStream(
          streamFilters.movie_id,
          streamFilters.scenes_id,
          streamFilters.start_time,
          streamFilters.duration,
          streamFilters.isPreview,
          false,
        ),
      ];

      if (userLoggedIn) {
        streamAndStillsPromise.push(
          fetchVideoStills(stillsFilters.movie_id, stillsFilters.start_time, stillsFilters.duration),
        );

        // get smoothStream for chromecast
        streamAndStillsPromise.push(
          fetchVideoStream(
            streamFilters.movie_id,
            streamFilters.scenes_id,
            streamFilters.start_time,
            streamFilters.duration,
            streamFilters.isPreview,
            true,
          ),
        );
      }

      Promise.all(streamAndStillsPromise)
        .then((resp = []) => {
          const dash = resp[0].data?.data;
          const stills = resp[1]?.data?.data || []; // preview photos
          const smoothStream = resp[2]?.data?.data || ''; // chromecast smoothStream
          // playerSrc - if no preview from gandolf, fetch from AEBN
          const videoSrcFromGandolf = videoData.videos[0]?.sources[0]?.src;
          const playerSrc = !userLoggedIn && videoSrcFromGandolf ? videoSrcFromGandolf : dash;

          const playerData = [
            {
              ...videoData.videos[0],
              id: videoData.sceneId || videoData.movieId, // used in player package
              sources: [{ src: playerSrc, smoothStream }], // used in player package
              startTimeSeconds: 0, // used in player package
              name: videoData.title, // used in player package
              movieId: videoData.movieId, // used in player package
              sceneId: videoData.sceneId, // used in player package
              sceneIndex, // used in player package
            },
          ];

          videoData.videos = playerData;
          videoData.allPhotos = stills;

          const { player } = getPlayerData();
          if (player) {
            player.src(videoData.videos?.[0]?.sources[0]?.src);
            player.poster(videoData.videos[0]?.poster);

            if (firstMounthRef.current) {
              player.play();
            }
            firstMounthRef.current = true;
          }

          if (!userLoggedIn && !props.omitPreviewsCount) {
            // prevents user click on player
            const { pmcCookie } = getPreviewMaxCount();

            if (pmcCookie === 0) {
              videoData.previewMaxCountOverlay = true;
            }
          }

          setState((prevState) => ({ ...prevState, ...videoData }));
        })
        .catch((err) => {
          console.log(err);
          setState((prevState) => ({ ...prevState, isError: true }));
          updateMovieDetailsPage([], [], false, true);
        })
        .finally(() => {
          setState((prevState) => ({ ...prevState, loading: false }));
        });
    };

    const fetchVideoDetailsAndAds = (abortController) => {
      const movieDetailsAndAdsPromise = [getMovieDetails(props.movieId, abortController)];

      if (!props.omitAds) movieDetailsAndAdsPromise.push(getVideoAds(props.movieId));

      // on movie/scene change stop playing and display loader
      const { player } = getPlayerData();
      if (player) {
        const playerElem = document.querySelector('.video-js');
        if (playerElem) {
          try {
            player.pause();
            playerElem.classList.add('vjs-waiting');
          } catch (e) {
            console.log('playerElem.classList.add', e);
          }
        }
      }

      Promise.all(movieDetailsAndAdsPromise)
        .then((resp = []) => {
          const movie = resp[0]?.data?.data || {};
          const ads = resp[1] || {};
          movie.ads = ads;

          const { videoData, streamFilters, stillsFilters } = getVideoData(movie);

          // set state for video details
          setState((prevState) => ({ ...prevState, ...videoData }));

          // set state for video stream and stills
          fetchVideoStreamAndStils({ videoData, streamFilters, stillsFilters });

          // for episodes and series
          const alteredScenes = videoData.scenes.map((item) => {
            item.movieId = videoData.movieId;
            return item;
          });
          updateMovieDetailsPage(alteredScenes, videoData.recommendations, false, false);
        })
        .catch((err) => {
          console.log(err);
          setState((prevState) => ({ ...prevState, isError: true }));
          updateMovieDetailsPage([], [], false, true);
        });
    };

    const abortController = new AbortController();
    fetchVideoDetailsAndAds(abortController);

    return () => {
      abortController.abort();
      if (!props.playlistPage) {
        firstMounthRef.current = false;
      }
      setState(initialState);

      updateMovieDetailsPage([], [], false, false);
    };
    // eslint-disable-next-line
  }, [props.movieId, props.sceneId, userLoggedIn, sceneIndex]);

  // on load
  useGtag({
    data: {
      event: 'videoWatch',
      page_title: path,
      page_location: window.location.href,
      page_path: path,
      event_label: 'video_page',
      category: 'video',
      action: 'video_page_view',
      logged: userLoggedIn ? 'Yes' : 'No',
      nats_member_id: memberid || 'loggedoutuser',
    },
    skipTracking: path === `/${ROUTES.diag}`,
  });

  const handleFavoritesBtn = (elem, isInFav) => {
    try {
      if (isInFav) {
        elem.classList.add('fas');
        elem.classList.remove('far');
        elem.setAttribute('title', 'Remove from favorites');
      } else {
        elem.classList.add('far');
        elem.classList.remove('fas');
        elem.setAttribute('title', 'Add to favorites');
      }
    } catch (err) {
      console.error('handleFavoritesBtn', err);
    }
  };

  const getPlayerData = () => {
    const { player, fullPlayer } = VideoPlayController.getVideoPlayer(pidRef.current) || {};
    const { name: movieName, movieId, sceneId, sceneIndex } = fullPlayer?.props?.data?.videos?.[0] || {};
    return { player, movieName, movieId, sceneId, sceneIndex };
  };

  const onFirstPlay = () => {
    // variables outside the player cannot be accessed here !!!
    let { movieName, movieId, sceneId, sceneIndex } = getPlayerData();

    playerAnalytics({
      event: 'videoPlay',
      category: 'video',
      action: `Play - ${movieId || 'Unknown ID'} - ${movieName || 'Unknown Title'} - ${sceneIndex || 'Unknown Scene'}`,
      event_label: 'video_click',
      videoStatus: 'playing',
      event_url: window.location.pathname,
      hostname: window.location.hostname,
      logged: userLoggedIn ? 'Yes' : 'No',
      movieId: movieId,
      movieTitle: movieName,
      sceneNumber: sceneIndex || 'Unknown Scene',
    });

    if (userLoggedIn) {
      if (sceneId) movieId = undefined;

      if (!props.addVideoToHistory) {
        addToViewingHistory(movieId, sceneId).catch((err) => console.log(err));
      }

      isInFavorites(movieId, sceneId)
        .then((resp) => {
          isHeartIconFilledRef.current = resp.data?.data || false;
          const btn = document.querySelector('.vjs-control-bar .fa-heart');

          if (btn) {
            if (isHeartIconFilledRef.current) {
              handleFavoritesBtn(btn, true);
            } else {
              handleFavoritesBtn(btn, false);
            }
          }
        })
        .catch((err) => console.log(err));
    } else {
      if (!props.omitPreviewsCount) {
        const { pmcCookie, preview_mc, preview_tp } = getPreviewMaxCount();
        if (pmcCookie === 0) return;
        const cookieValue = btoa([pmcCookie - 1 + '-' + preview_mc]);
        setBrowserCookie(PREVIEWS_MAX_COUNT, cookieValue, preview_tp);
      }
    }
  };

  const getPreviewMaxCount = () => {
    let previewMaxCountCookie = getCookie(PREVIEWS_MAX_COUNT) || btoa(preview_mc);
    try {
      previewMaxCountCookie = atob(previewMaxCountCookie); // might throw exception
    } catch (e) {
      console.log(e);
    }

    let pmcCookie = +previewMaxCountCookie?.split('-')[0];
    const pmcApp = +previewMaxCountCookie?.split('-')[1] || preview_mc; // prev value from Gandolf

    if (preview_mc !== pmcApp) {
      // if value in Gandolf is changed, use the new value
      pmcCookie = preview_mc;
    }

    return { pmcCookie, preview_mc: preview_mc, preview_tp: preview_tp };
  };

  const onHeartIconClick = (e) => {
    let { movieId, sceneId, movieName } = getPlayerData();
    if (sceneId) movieId = undefined;

    const btn = e.target;

    if (btn) {
      if (isHeartIconFilledRef.current) {
        handleFavoritesBtn(btn, false);
        removeFromFavorites(movieId, sceneId)
          .then(onVideoActionSuccess.bind(this, 'Remove from favorites success!'))
          .catch((err) => console.log(err));
        isHeartIconFilledRef.current = false;
      } else {
        handleFavoritesBtn(btn, true);
        addToFavorites(movieId, sceneId)
          .then(onVideoActionSuccess.bind(this, 'Add to favorites success!'))
          .catch((err) => {
            if (err.response?.status === 400) {
              displayInfoNotification({
                duration: 3,
                message: 'Maximum 500 videos allowed!',
              });
            }
            console.log(err);
          });
        isHeartIconFilledRef.current = true;
      }
    }

    const videoId = sceneId ? sceneId : movieId;
    playerAnalytics({
      event: 'addToFavorites',
      category: 'Favorites',
      action: 'Add',
      [sceneId ? 'sceneId' : 'movieId']: videoId,
      movieTitle: movieName,
    });
  };

  const onPlusIconClick = () => {
    setState((prevState) => ({
      ...prevState,
      showPlaylistsPlayerPopup: !prevState.showPlaylistsPlayerPopup,
    }));

    const { player } = getPlayerData();
    if (player) {
      if (player.isFullscreen()) {
        player.exitFullscreen();
      }
    }

    const { movieId, sceneId, movieName } = getPlayerData();
    const videoId = sceneId ? sceneId : movieId;
    playerAnalytics({
      event: 'addToPlaylist',
      category: 'Playlist',
      action: 'Add',
      [sceneId ? 'sceneId' : 'movieId']: videoId,
      movieTitle: movieName,
    });
  };

  const playerAnalytics = (dataObj = {}) => {
    if (props.omitPreviewsCount) return; // -> /diag page

    onEventGtag({
      category: 'video_click',
      nats_member_id: memberid || 'loggedoutuser',
      ...dataObj
    });
  };

  const onPlayerSetupFinished = (playerId) => {
    pidRef.current = playerId;

    // for VideosPlaylist.js
    if (props.getPlayer) {
      props.getPlayer(playerId, VideoPlayController);
    }

    const { player } = getPlayerData();
    if (player) {
      player.on('pause', () => playerAnalytics({ event: 'videoPause', videoStatus: 'paused' }));
      player.on('ended', () => playerAnalytics({ event: 'videoEnded', videoStatus: 'ended' }));
      player.on('enterpictureinpicture', () => {
        playerAnalytics({
          event: 'videoPictureInPicture',
          videoStatus: 'picture_in_picture',
        });
      });
      player.on('volumechange', () => {
        playerAnalytics({
          event: 'videoVolumeChange',
          videoStatus: 'volume_change',
          volume: player.muted() ? 0 : player.volume().toFixed(1),
        });
        if (player.muted()) {
          playerAnalytics({ event: 'videoMute', videoStatus: 'muted' });
        }
      });
      player.on('fullscreenchange', () => {
        playerAnalytics({
          event: 'videoFullScreen',
          videoStatus: 'full_screen',
        });
      });
      player.on('seeked', () => {
        if (player.currentTime() < 3) return; // omit event on first play
        playerAnalytics({
          event: 'videoScan',
          videoStatus: 'scanned',
          currentTime: convertSecondsToTime(parseInt(player.currentTime())),
        });
      });

      // ChromeCast tracking events
      const playerEl = player.el_;
      const controls = playerEl.querySelector('.vjs-control-bar');
      let cCastBtn;

      const findBtnInterval = setInterval(() => {
        cCastBtn = controls.querySelector('.ChromeCastButton');

        if (cCastBtn) {
          cCastBtn.addEventListener('click', () => {
            playerAnalytics({ event: 'videoCasting', videoStatus: 'casting' });
          });
          clearInterval(findBtnInterval);
        }
      }, 1000);
    }
  };

  const onVideoActionSuccess = (msg) => {
    displayInfoNotification({
      duration: 3,
      message: msg,
    });
  };

  const renderGallery = () => {
    if (!openGallery || gallery.length === 0) return null;

    const galleryURLs = handleGallery(gallery) || [];
    return <ImagesLightBox images={galleryURLs.length ? galleryURLs : allPhotos} onClose={onOpenGallery} />;
  };

  const renderTitle = () => {
    let view = <ImageLoadingSkeleton className="VideoTitleLoader" />;

    if (!loading) {
      view = (
        <div className="VideoTitle">
          <span className="NowPlaying">now playing</span>
          <h4>{title}</h4>
          <span className="Duration">({runTime})</span>
        </div>
      );
    }

    return view;
  };

  const membersOnly = () => SignInController.showSignInDialog(true);

  const onAddClick = ({ goToUrl, selectedTarget }) => {
    showEntityDetailsPage(goToUrl, selectedTarget);
  };

  const renderVideoPlayer = () => {
    const data = {
      ads: ads,
      autoplay: false,
      thumbnails: allPhotos,
      isUserLoggedIn: () => userLoggedIn,
      isHeartIconFilled: isHeartIconFilledRef.current,
      onHeartIconClick: onHeartIconClick,
      onPlusIconClick: onPlusIconClick,
      onAddClick,
      onFirstPlay,
      playerConfiguration,
      membersOnly: membersOnly,
      progressBarColors: progressBarColors,
      videos,
    };

    return (
      <div className="FullPlayerContainer">
        {previewMaxCountOverlay ? (
          renderPreviewMaxCountOverlay()
        ) : (
          <FullPlayer data={data} onPlayerSetupFinished={onPlayerSetupFinished} key={userLoggedIn} />
        )}
      </div>
    );
  };

  const closePlaylistPopup = () =>
    setState((prevState) => ({
      ...prevState,
      showPlaylistsPlayerPopup: false,
    }));

  const renderPlaylistPlayerPopup = () => {
    if (!showPlaylistsPlayerPopup) return null;

    let { movieId, sceneId } = getPlayerData();
    if (sceneId) movieId = undefined;

    return (
      <PlaylistsPlayerPopup
        ref={playlistsPlayerPopupRef}
        callback={() => {
          setState((prevState) => ({
            ...prevState,
            showPlaylistsPlayerPopup: false,
          }));
        }}
        movieId={movieId}
        sceneId={sceneId}
        create
        closePopup={closePlaylistPopup}
      />
    );
  };

  const onOpenGallery = () => {
    if (loading) return;

    setState((prevState) => ({
      ...prevState,
      openGallery: !prevState.openGallery,
    }));

    let { movieId } = getPlayerData();
    playerAnalytics({
      event: 'seeStills',
      category: 'Navigation',
      movieId,
    });
  };

  const handleDownloadLinkClick = (bitrate) => {
    setState((prevState) => ({ ...prevState, downloadLoading: true }));
    let { movieId, sceneId } = state;

    let id = movieId;
    let type = 'movie';

    if (sceneId) {
      id = sceneId;
      type = 'scene';
    }

    getVideoDownloadUrl(id, bitrate, type)
      .then((resp) => {
        const url = resp?.data?.data?.mediaUrl;
        if (!url) throw new Error('No media url found!');

        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', url);
        iframe.setAttribute('style', 'display:none');
        document.body.appendChild(iframe);

        const intervalID = setInterval(() => {
          // iframe.onload not working in this context, so remove it after 5sec
          if (iframe) {
            document.body.removeChild(iframe);
            clearInterval(intervalID);
          }
        }, 5000);
      })
      .catch((err) => {
        const status = err.response?.status;

        if (status === 403) {
          displayErrorNotification({
            duration: 15,
            title: 'DOWNLOAD ERROR!',
            message:
              'Your have reached your maximum download limit for the next hour. To download this video, please come back later.',
          });
        } else {
          displayErrorNotification({
            duration: 3,
            title: 'Failed to download!',
          });
        }
        console.error(err);
      })
      .finally(() => setState((prevState) => ({ ...prevState, downloadLoading: false })));

    playerAnalytics({
      event: sceneId ? 'downloadScene' : 'downloadMovie',
      category: 'Navigation',
      [sceneId ? 'sceneId' : 'movieId']: id,
    });
  };

  const renderDownloads = () => {
    if (!showDownloadIcon) return null;

    return (
      <MovieDownloads
        downloadLoading={downloadLoading}
        downloadOptions={downloadOptions}
        onClick={handleDownloadLinkClick}
      />
    );
  };

  const renderPreviewMaxCountOverlay = () => {
    const goToJoin = () => { navigate('/join') };

    return (
      <div className="PreviewMaxCountOverlay" style={{ backgroundImage: `url(${videos[0]?.poster})` }}>
        <span className="PreviewMaxCountOverlayText" onClick={goToJoin}>
          {t('MainDashboardHeader.joinNow')}
        </span>
      </div>
    );
  };

  const getMovieDetailsUrl = () => getMovieDetailsPageRoute(state.movieId, title);

  if (isError && props.updateMovieDetailsPage) {
    return (
      <div className="NotAvailable" onClick={showApplicationStart}>
        <span className="ClickToHomePage">{t('MovieDetailsPage.clickToHomePageMsg')}</span>
      </div>
    );
  }

  if (isError && !props.updateMovieDetailsPage) return null;

  return (
    <div className="BoxCoverPlayerDetailsComponent">
      <div className="MoviePlayerRow">
        {renderTitle()}
        {renderVideoPlayer()}
        {renderPlaylistPlayerPopup()}
      </div>

      <div className="PlayerInfoIconsContainer">
        {renderDownloads()}

        {state.sceneId && (
          <Link
            to={getMovieDetailsUrl()}
            className="GoToMovieIcon"
            title="Go to full movie"
            onClick={() => {
              playerAnalytics({
                event: 'fullMovieButton',
                category: 'Navigation',
                movieId: state.movieId,
              });
            }}
          >
            <i className="ISvgIcon" style={{ pointerEvents: 'none' }}>
              {playFilmIcon({ size: isWeb() ? '30px' : '30px' })}
            </i>
          </Link>
        )}
        <i className="fas fa-camera GalleryIcon" onClick={onOpenGallery} />
      </div>

      <MovieSceneDetails
        data={{
          stars,
          studios,
          directors,
          categories,
          description,
          images,
          url: getMovieDetailsUrl(),
        }}
      />

      {renderGallery()}
    </div>
  );
};

BoxCoverPlayerDetailsComponent.displayName = 'BoxCoverPlayerDetailsComponent';

BoxCoverPlayerDetailsComponent.propTypes = {
  movieId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sceneId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateMovieDetailsPage: PropTypes.func,
  getPlayer: PropTypes.func,
  omitAds: PropTypes.bool,
  omitPreviewsCount: PropTypes.bool,
  playlistPage: PropTypes.bool,
  addVideoToHistory: PropTypes.bool,
};

export default BoxCoverPlayerDetailsComponent;
