import React from 'react';

import SinglePlayer from '../SinglePlayer/SinglePlayer';

export default function MissPineapple2021Page() {
  return (
    <>
      <SinglePlayer
        src='https://d34kds0xkdmtq8.cloudfront.net/FSP056_SA.mp4'
        poster='https://spotlight.nakedcdn.com/nakedsword/img/ns_play/WFS_WNS_MissPineapple_16x9_Recording.jpg'
      />

      <h2>Miss Pineapple 2021 | WATCH FREE AUGUST 13 - AUGUST 15</h2>
      <p>
        The worlds of porn and drag collide in a spectacular event that celebrates diversity and self-expression
        while raising money pineapplesupport.org, providing free and subsidized mental health services to the
        adult industry.
      </p>
      <p>
        Seabrook, and Adrian Hart turn into stunning dolls and compete for the coveted title of Miss Pineapple
        2021. Starring Dakota Payne, aka Miss Pineapple 2020 Gemini Dai, hosts Marc MacNamara and Sister Roma and
        celebrity guest judges the "Dynamic Duo of Porn" Wesley Woods and Silvia Saige, Falcon|NakedSword
        Superstar Exclusive Max Konnor and RuPaul's Drag Race and All-Stars fan favorite Detox!
      </p>
    </>
  );
} 