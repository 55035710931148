import { createContext, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';

import { LoadingController } from '../controllers/loading-controller/loading.controller';
import { authLogin, authLogout, authMe } from '../services/auth-service/auth.service';
import { displayBenefitsModalDialog } from '../pages/MainDashBoard/MemberDealsPage/MemberDealsLoginModal';
import { onEventGtag } from '../hook/useGtag';
import { cleanupToken, EXPIRES, getUserToken, hasTokenExpired, saveToken, saveYuliCookie } from '../services/token-service/token.service';
import { useWLconfigContext } from './WLConfigContext';
import { PREVIEWS_MAX_COUNT, setBrowserCookie } from '../services/util-service/util.service';
import { SignInController } from '../controllers/sign-in-controller/sign-in.controller';
import { getUserLocalData, removeUserLocalData, setUserLocalData } from '../services/users-service/users.service';
import { ROUTES } from '../services/navigation/navigation.routes';
import { showStreamBlockedPage } from '../services/navigation/navigation.service';
import { AGE_WARNING_OVERLAY } from '../components/AgeWarningOverlay/AgeWarningOverlay';

const AuthContext = createContext();

export const contextLogout = {}; // force logout on all devices from BE side

const AuthContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { wl_config: { preview_max_count } = {} } = useWLconfigContext();

  const [state, setState] = useState({
    userLoggedIn: false,
    showErrorLabel: false
  });
  const pathname = window.location.pathname;

  useEffect(() => {
    const hasFEdata = !!(getUserToken() && !hasTokenExpired());
    if (hasFEdata) {
      setState(prevState => ({ ...prevState, userLoggedIn: true }));
    }
  }, []);

  useEffect(() => {
    const singleSignOff = (type) => {
      const event = type === 'add' ? 'addEventListener' : 'removeEventListener';
      document[event]('visibilitychange', () => {
        if (!document.hidden) {
          const userDate = getUserLocalData();

          if (Object.keys(userDate).length === 0) {
            setState(prevState => ({ ...prevState, userLoggedIn: false }));
          }
        }
      });
    };

    singleSignOff('add');

    return () => singleSignOff('remove');
  }, []);

  const login = (password, username) => {
    if (password && username) {
      const message = t('SignIn.authenticatingUserMsg');
      LoadingController.showLoadingMask(message);

      authLogin(username, password)
        .then(resp => {
          const { expiresIn, jwt } = resp.data?.data || {};
          const userData = getUserLocalData();
          const nats_member_id = userData.memberid;

          if (process.env.REACT_APP_PROPERTY_ID === '1' || process.env.REACT_APP_PROPERTY_ID === '22') {
            displayBenefitsModalDialog();
          }

          onEventGtag({
            event: 'userLoggedIn',
            userType: 'member',
            userStatus: 'loggedin',
            loginURL: pathname,
            nats_member_id: nats_member_id,
            hostname: window.hostname,
            logged: 'Yes',
          });

          saveToken(jwt, expiresIn);
          SignInController.closeSignInDialog();
          // delete preview_max_count cookie when log in 
          setBrowserCookie(PREVIEWS_MAX_COUNT, preview_max_count);

          return resp;
        })
        .then(resp => {
          const { expiresIn } = resp.data?.data || {};

          authMe()
            .then(resp => {
              const data = resp.data?.data || {};
              setUserLocalData(data);
              data[EXPIRES] = expiresIn;
              saveYuliCookie(data);
              setState(prevState => ({ ...prevState, userLoggedIn: true, showErrorLabel: false }));
              LoadingController.removeLoadingMask();

              if (pathname.includes(ROUTES.signin)) {
                navigate(`/${ROUTES.myAccount}`);
              } else if (pathname.includes(ROUTES.ageVerificationLanding)) {
                window.location.href = '/';
              }
            })
            .catch((err) => {
              LoadingController.removeLoadingMask();
              console.log(err);
            });
        })
        .catch(err => {
          LoadingController.removeLoadingMask();
          const { status } = err?.response || {};

          switch (status) {
            case 401: // 'Unauthorized login'
              setState(prevState => ({ ...prevState, showErrorLabel: true }));
              break;
            case 403: // "IP Limit Exceeded"
              SignInController.closeSignInDialog();
              showStreamBlockedPage();
              break;
            default:
              break;
          }
        });
    }
  };

  const logout = (callback) => {
    LoadingController.showLoadingMask(t('MainDashboardHeader.loginOutMsg'));

    authLogout()
      .then(() => {
        removeUserLocalData();
        cleanupToken();
        const userData = getUserLocalData();
        const nats_member_id = userData.memberid;
        localStorage.removeItem(AGE_WARNING_OVERLAY);

        onEventGtag({
          event: 'userLoggedOut',
          userType: 'nonmember',
          userStatus: 'loggedout',
          logged: 'No',
          logoutURL: pathname,
          hostname: window.location.hostname.toLowerCase().trim(),
          nats_member_id: nats_member_id,
        });

        setState(prevState => ({ ...prevState, userLoggedIn: false }));

        navigate(`/${ROUTES.specialOffers}`);
        LoadingController.removeLoadingMask();
        if (callback) {
          callback();
        }
      })
      .catch(err => {
        LoadingController.removeLoadingMask();
        console.log(err);
      });
  };

  const removeErrorLabel = () => setState(prevState => ({ ...prevState, showErrorLabel: false }));

  contextLogout.logout = () => setState(prevState => ({ ...prevState, userLoggedIn: false }));

  return <AuthContext.Provider value={{
    ...state,
    login,
    logout,
    removeErrorLabel
  }}>{children}</AuthContext.Provider>;
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('AuthContext must be used within a AuthContextProvider!');
  }
  return context;
};

export { AuthContextProvider, useAuthContext };
