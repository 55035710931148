import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useBanners from '../../../hook/useBanners';

import BannerSetViewFunc from '../../../components/BannerSetView/BannerSetViewFunc';
import { ErrorMessage } from '../../../components/ErrorBoundary/ErrorBoundary';
import AlphabeticView from '../../../components/AlphabeticView/AlphabeticView';
import SlickSlider from '../../../components/SlickSlider/SlickSlider';

import { getSexActDetailsRoute, getThemeDetailsRoute } from '../../../services/navigation/navigation.service.routes';
import { getThemes } from '../../../services/tags-service/tags.service';

import './ThemesPage.scss';

const sections = {
  sexActs: 'sexActs',
  themes: 'themes',
};

const ThemesPage = () => {
  const themesBanners = useBanners(sections.themes, 1);
  const { t } = useTranslation();
  const [content, setContent] = useState({
    themes: {
      alphabet: [],
      items: {},
      loaded: false,
    },
    sexActs: {
      alphabet: [],
      items: {},
      loaded: false,
    },
  });
  const [activeSection, setActiveSection] = useState(sections.themes);
  const [error, setError] = useState(false);

  useEffect(() => {
    const nameSorter = ({ name: custom_name }, { name }) => {
      let order = 0;
      if (custom_name < name) {
        order = -1;
      } else if (custom_name > name) {
        order = 1;
      }
      return order;
    };

    const getItemsFromResponse = (response) => {
      const { categories, sex_acts } = response.data.data;
      return {
        themes: categories.sort(nameSorter),
        sexActs: sex_acts.sort(nameSorter),
      };
    };

    const loadData = (response) => {
      const { themes, sexActs } = getItemsFromResponse(response);
      let themeData = themes.reduce(reduceData, { items: {}, alphabet: [] });
      let sexActsData = sexActs.reduce(reduceData, { items: {}, alphabet: [] });
      setContent({
        themes: {
          loaded: true,
          items: themeData.items,
          alphabet: themeData.alphabet,
        },
        sexActs: {
          loaded: true,
          items: sexActsData.items,
          alphabet: sexActsData.alphabet,
        },
      });
    };

    getThemes()
      .then(loadData)
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  }, []);

  const reduceData = (obj, t) => {
    const letter = t.name[0];
    if (!obj.alphabet.includes(letter)) {
      obj.alphabet.push(letter);
      obj.items[letter] = [t];
    } else {
      obj.items[letter].push(t);
    }
    return obj;
  };

  const parseItem = ({ name, id }) => {
    return {
      id,
      name,
      themesRoute: getThemeDetailsRoute(id, name),
      sexActsRoute: getSexActDetailsRoute(id, name),
    };
  };

  const renderNavigationEntry = (label, key) => {
    const classes = ['Entry'];
    if (activeSection === key) {
      classes.push('Active');
    }
    return (
      <span className={classes.join(' ')} onClick={() => setActiveSection(key)}>
        {label}
      </span>
    );
  };

  const getNavigation = () => {
    return (
      <div className="AlphabeticNavigation">
        <label>{t('ThemesAlphabeticDisplay.title')}</label>
        <div>
          <span className="ShowMe">{t('ThemesAlphabeticDisplay.showMe')}</span>
          {renderNavigationEntry(t('ThemesAlphabeticDisplay.themes'), sections.themes)}
          <span className="Separator">|</span>
          {renderNavigationEntry(t('ThemesAlphabeticDisplay.sexActs'), sections.sexActs)}
        </div>
      </div>
    );
  };

  if (error) return <ErrorMessage />;

  return (
    <div className="ThemesPage">
      <SlickSlider slides={themesBanners} />
      <BannerSetViewFunc bannerName="promo" />
      <AlphabeticView
        data={content}
        translationKey={'ThemesAlphabeticDisplay'}
        parseItem={parseItem}
        renderAlphabeticNavigation={getNavigation}
        activeSection={activeSection}
      />
      <BannerSetViewFunc bannerName="tour" />
    </div>
  );
};

ThemesPage.displayName = 'ThemesPage';

export default ThemesPage;
