import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FullPlayer, VideoPlayController } from '@falconstudios/ns-player';
import PropTypes from 'prop-types';

import SceneImage from '../SceneImage/SceneImage';

import { useWLconfigContext } from '../../context/WLConfigContext';

import './SceneImageHoverPlayer.scss';

const SceneImageHoverPlayer = (props) => {
  const mouseStates = {
    loading: 2,
    left: 3,
  };
  const pid = useRef(); // playerId
  const mouseEnterTimeoutId = useRef();
  const previewHoverTimeoutTime = 1000;
  const playerInitializationTime = useRef();
  const progressBarColors = useRef({});
  const { wl_playerConfig: { progressBarColors: playerBarColor = {} } = {} } = useWLconfigContext();

  const [state, setState] = useState({
    videoLoaded: false,
    loading: false,
    isMp4File: false,
    isHovered: mouseStates.left,
    streamUrl: undefined,
  });
  const progressBarStyle = useRef({});
  const { streamUrl } = state;

  const playVideoIfPlayerIsInitialized = useCallback(() => {
    if (pid.current && streamUrl) {
      const { player } = VideoPlayController.getVideoPlayer(pid.current) || {};
      if (player) {
        const paused = player.paused();
        const readyState = player.readyState();
        if (paused && readyState === 4) {
          const promise = player.play();
          if (promise.catch) {
            promise.catch(() => { });
          }
        }
      }
    }
  }, [streamUrl]);

  useEffect(() => {
    const onPlayerInitialized = (player) => {
      const { playerId, initializationTime } = player;
      if (playerInitializationTime.current === initializationTime) {
        pid.current = playerId;
        playVideoIfPlayerIsInitialized();
      }
    };

    progressBarColors.current = playerBarColor;
    progressBarStyle.current = {
      '--progres-bar-color': progressBarColors.current?.primary,
    };
    const playerInitializedEvent = VideoPlayController.playerInitializedEvent.subscribe(onPlayerInitialized);
    return () => {
      playerInitializedEvent.unsubscribe();
      clearMouseTimeout();
    };
  }, [playVideoIfPlayerIsInitialized, playerBarColor]);

  useEffect(() => {
    if (state.isHovered === mouseStates.loading) {
      playVideoIfPlayerIsInitialized();
    }
  }, [state.isHovered, mouseStates.loading, playVideoIfPlayerIsInitialized]);

  const clearMouseTimeout = () => {
    if (mouseEnterTimeoutId.current) {
      clearTimeout(mouseEnterTimeoutId.current);
    }
  };

  const fetchStreamUrl = () => {
    const { sampleVideoUrl } = props;
    if (sampleVideoUrl) {
      setupStreamData(
        {
          data: {
            data: sampleVideoUrl,
          },
        },
        true,
      );
    }
  };

  const onFullPlayerInitialized = (initTime) => {
    playerInitializationTime.current = initTime;
  };

  const onMouseEnter = () => {
    setState((prev) => ({
      ...prev,
      isHovered: mouseStates.loading,
    }));
  };

  const renderInitialDisplay = () => {
    const { isHovered } = state;
    const { heroStillUrl, alt } = props;
    return <SceneImage alt={alt} url={heroStillUrl} hidden={isHovered === mouseStates.loading} />;
  };

  const onMouseEnterDelay = (event) => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    clearMouseTimeout();
    if (streamUrl) {
      onMouseEnter(event);
    } else {
      mouseEnterTimeoutId.current = setTimeout(() => {
        fetchStreamUrl();
        onMouseEnter(event);
      }, previewHoverTimeoutTime);
    }
  };

  const onMouseLeave = (event) => {
    clearMouseTimeout();
    setIsHovered(mouseStates.left);
    setState((prev) => ({
      ...prev,
      loading: false,
    }));
  };

  const onMouseOver = () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
  };

  const renderMouseEventOverlay = () => {
    const { loading, videoLoaded } = state;
    return (
      <div
        className="MouseEventOverlay"
        onMouseOver={onMouseOver}
        onMouseEnter={onMouseEnterDelay}
        onMouseLeave={onMouseLeave}
      >
        {!videoLoaded && loading ? (
          <div className="ProgressContainer" style={progressBarStyle.current}>
            <div className="Loading"></div>
          </div>
        ) : null}
      </div>
    );
  };

  const renderPlayer = () => {
    const { isMp4File } = state;
    let view = null;
    if (streamUrl) {
      const data = {
        isPreview: true,
        loopPreviewVideo: true,
        isHoverPreview: true,
        videos: [
          {
            sources: [
              {
                src: streamUrl, // by default try to load
                type: isMp4File ? 'video/mp4' : 'application/x-mpegURL',
              },
            ],
          },
        ],
        progressBarColors: progressBarColors.current,
        sampleReadyToPlay: sampleReadyToPlay,
      };
      view = <FullPlayer data={data} onFullPlayerInitialized={onFullPlayerInitialized} />;
    }

    return view;
  };

  const sampleReadyToPlay = (player) => {
    setState((prev) => ({
      ...prev,
      videoLoaded: true,
    }));
  };

  const setIsHovered = (isHovered) => {
    if (isHovered === mouseStates.left && pid.current) {
      const playerData = VideoPlayController.getVideoPlayer(pid.current);
      if (playerData) {
        const { player } = playerData;
        player.pause();
      }
    }
    setState((prev) => ({ ...prev, isHovered }));
  };

  const setupStreamData = (response, isMp4File = false) => {
    setState((prev) => ({
      ...prev,
      streamUrl: response.data.data,
      isMp4File,
    }));
  };

  return (
    <div className="SceneImageContainer">
      <div className="MainView">
        {renderInitialDisplay()}
        {renderPlayer()}
      </div>
      {renderMouseEventOverlay()}
    </div>
  );
};

SceneImageHoverPlayer.propTypes = {
  alt: PropTypes.string.isRequired,
  endTimeSeconds: PropTypes.number,
  heroStillUrl: PropTypes.string,
  movieId: PropTypes.number,
  sampleVideoUrl: PropTypes.string,
  startTimeSeconds: PropTypes.number,
};

export default SceneImageHoverPlayer;
