import React, { useEffect, useRef, useState } from 'react';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { Subject } from 'rxjs';

import AlphabeticNavigation from '../../../../components/AlphabeticNavigation/AlphabeticNavigation';
import ImageLoadingSkeleton from '../../../../components/ImageLoadingSkeleton/ImageLoadingSkeleton';
import ShowMeSortByNavigation from '../../../../components/ShowMeSortByNavigation/ShowMeSortByNavigation';

import { getStarDetailsRoute } from '../../../../services/navigation/navigation.service.routes';
import { showStarLetterListPage } from '../../../../services/navigation/navigation.service';
import { getStarsFeedFiltered } from '../../../../services/stars/stars.service';
import PageIndicator from '../../../../components/PageIndicator/PageIndicator';
import { isWeb } from '../../../../services/util-service/util.service';

import './StarsLetterCatalogDisplay.scss';

const StarsLetterCatalogDisplay = (props) => {
  const { t } = useTranslation();
  const navigationValueChangeNotifier = useRef(new Subject());
  const [allData, setAllData] = useState({
    loaded: false,
    letter: '',
    letters: undefined,
    stars: [],
    pagination: {
      current_page: 1,
      last_page: 1,
    },
  });
  const { loaded, letters: stateLetters, stars, pagination } = allData;
  let { letter: propsLetter, letters: propsLetters } = props;
  // Since # is not a valid route, when the letter is "misc" we display all miscellaneous stars
  if (propsLetter === 'misc') propsLetter = '#';

  useEffect(() => {
    if (propsLetters?.length === 0) return;
    const mapShowMeLetter = (letter) => {
      return {
        name: letter,
        value: letter,
      };
    };

    const groupLetters = (letters) => {
      const groupedLetters = [];
      const symbols = {
        name: '#',
        value: [],
      };
      const regex = new RegExp(/[A-Za-z]/);
      for (const letter of letters) {
        if (regex.test(letter)) {
          groupedLetters.push(mapShowMeLetter(letter));
        } else {
          symbols.value.push(letter);
        }
      }
      if (symbols.value.length) {
        symbols.value = 'misc';
        groupedLetters.splice(0, 0, symbols);
      }

      return groupedLetters;
    };

    const getStars = async () => {
      try {
        const response = await getStarsFeedFiltered({
          per_page: 100,
          page: pagination?.current_page,
          sort_by: 'a-z',
          ...(!!propsLetter && { name_starts_with: propsLetter }),
          star_images_custom_only: 1,
          star_images_master_only: 1,
        });

        if (response.status === 200) {
          let { stars, pagination } = response.data.data;
          setAllData((prev) => ({
            ...prev,
            loaded: true,
            stars,
            pagination,
            letters: groupLetters(propsLetters),
          }));
        }
      } catch (err) {
        console.log(err, 'StarsLetterCatalogDisplay');
      }
    };
    getStars();
  }, [propsLetter, propsLetters, pagination.current_page]);

  const onPageChange = (page) => {
    setAllData((prev) => ({
      ...prev,
      loaded: false,
      pagination: {
        ...prev.pagination,
        current_page: page,
      },
    }));
  };

  const onShowMeClick = (letter) => {
    if (letter === 'misc') return;
    setAllData((prev) => ({
      ...prev,
      letter,
      loaded: false,
      pagination: {
        ...prev.pagination,
        current_page: 1,
      },
    }));
    showStarLetterListPage(letter);
  };

  const renderFakePlaceholders = (itemCount) => {
    const items = [];
    for (let i = 0; i < itemCount; i++) {
      items.push(<ImageLoadingSkeleton key={i} />);
    }
    return items;
  };

  const renderMainNavigation = () => {
    let view = null;
    if (stateLetters && stateLetters.length !== 0) {
      if (!isWeb()) {
        view = (
          <AlphabeticNavigation
            onLetterChange={onShowMeClick}
            activeLetter={propsLetter}
            overridingCharacters={stateLetters}
            mobileScroll
          />
        );
      } else {
        view = (
          <ShowMeSortByNavigation
            onShowMeClick={onShowMeClick}
            showMeInitialValue={propsLetter}
            onValueChange={navigationValueChangeNotifier.current}
            showMeItems={stateLetters}
            hideShowMeSeparators={true}
          />
        );
      }
    }
    return view;
  };

  const renderStar = ({ name, id }, index) => {
    return (
      <Link
        to={getStarDetailsRoute(id, name)}
        className="StarsLetterCatalogDisplay-star"
        key={`${name}-${id}-${index}`}
      >
        {name}
      </Link>
    );
  };

  const renderTitle = () => {
    let view = null;
    if (propsLetter) {
      const key = propsLetter === 'misc' ? 'titleHashtag' : 'title';
      view = t(`StarsLetterCatalogDisplay.${key}`) + propsLetter;
    }
    return view;
  };

  const baseClass = 'StarsLetterCatalogDisplay';
  const classes = [baseClass];
  if (!loaded || !stateLetters) {
    classes.push('Loading');
  }

  return (
    <div className={classes.join(' ')}>
      <div className="StarsLetterCatalogDisplay-title">{renderTitle()}</div>
      {renderMainNavigation()}
      <div className="StarsLetterCatalogDisplay-namesList">
        {loaded && stateLetters ? stars.map(renderStar) : renderFakePlaceholders(100)}
      </div>
      <PageIndicator page={pagination?.current_page} pageCount={pagination?.last_page} onPageChange={onPageChange} />
    </div>
  );
};

export default StarsLetterCatalogDisplay;
