import React from 'react';
import DealsImport from './DealsImport.js';
import BaseSectionHeader from '../../../components/BaseSectionHeader/BaseSectionHeader';
import { useTranslation } from 'react-i18next';

const MemberDealsPage = () => {
  const numberOfDisplayedOffers = 999;
  const { t } = useTranslation();

  return (
    <div className="MemberDealsPage">
      <BaseSectionHeader primaryText={t('MemberDealsPage.header')} />
      <DealsImport numberOfDisplayedOffers={numberOfDisplayedOffers} />
    </div>
  );
};

export default MemberDealsPage;
