import MemberPWReminder from './MemberPWReminder';
import React, { Component } from 'react';
import ModalDialog from '../ModalDialog/ModalDialog';
import { withTranslation } from 'react-i18next';

// import './SignIn.scss';

class ResetPassword extends Component {
  modalRef = React.createRef();

  render() {
    return (
      <ModalDialog className="SignInModal MyAccountSignInModal" forwardedRef={this.modalRef} closeModal={this.closeDialog}>
        <MemberPWReminder />
      </ModalDialog>
    );
  }
}

export default withTranslation()(ResetPassword);
