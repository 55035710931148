export const setUserNATSCode = (nats) => localStorage.setItem('nats_code', nats);

export const getUserNATSCode = () => localStorage.getItem('nats_code');

export const configureNATSToken = (natsCodeFromGandolf, url) => {
  const storedNatsCode = getUserNATSCode();
  const querySearch = new URLSearchParams(window.location.search);
  const natsCodeFromUrl = querySearch.get('nats');
  let generateJoinUrl = `${url}${storedNatsCode}/join`;

  if (natsCodeFromUrl) {
    generateJoinUrl = `${url}${natsCodeFromUrl}/join`;
    setUserNATSCode(natsCodeFromUrl);
    getNatsCode(natsCodeFromUrl, generateJoinUrl);
  } else if (natsCodeFromGandolf && !storedNatsCode) {
    generateJoinUrl = `${url}${natsCodeFromGandolf}/join`;
    setUserNATSCode(natsCodeFromGandolf);
    getNatsCode(natsCodeFromGandolf, generateJoinUrl);
  }

  return generateJoinUrl;
};

export const getNatsCode = (nats_code = null, joinUrl) => {
  // request to NATS so NATS can set its own cookies
  if (!nats_code) return;

  // eslint-disable-next-line
  const url = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/.exec(joinUrl)[0];
  const trackLink = `${url}/track/${nats_code}`;

  fetch(trackLink, {
    method: 'GET',
    mode: 'no-cors',
    credentials: 'include',
  })
    .catch((err) => console.log('getNatsCode - ' + err));
};