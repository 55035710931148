import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';

import { showSceneDetailsPage } from '../../../../services/navigation/navigation.service';
import {
  getSceneDetailsPageRoute,
  getStarDetailsRoute,
} from '../../../../services/navigation/navigation.service.routes';
import { convertSecondsToTime } from '../../../../services/util-service/date.service';

import SceneImage from '../../../../components/SceneImage/SceneImage';
import TruncateText from '../../../../components/TruncateText/TruncateText';

import './MovieScenes.scss';

const MovieScenes = ({ scenes = [] }) => {
  const { t } = useTranslation();

  const onSceneClick = (movieId, movieName, sceneId) => {
    showSceneDetailsPage(movieId, movieName, sceneId);
  };

  const renderImageComponent = (image, index, sceneId, movieId, movieName, alt) => {
    const url = image?.[0]?.url;
    if (!alt) {
      alt = url;
    }
    return (
      <Link
        to={getSceneDetailsPageRoute(movieId, movieName, index)}
        onClick={onSceneClick.bind(this, movieId, movieName, sceneId)}
        className="ImageContainer"
      >
        <SceneImage url={url} alt={alt} />
      </Link>
    );
  };

  const renderScene = (scene) => {
    const {
      cover_images,
      id,
      movieId,
      stars,
      endTimeSeconds,
      startTimeSeconds,
      index: sceneIndex,
      streaming_movie = {},
    } = scene;
    let view = null;
    const { title, titleNs } = streaming_movie;

    if (id) {
      const movieName = titleNs || title;
      view = (
        <>
          {renderImageComponent(cover_images, sceneIndex, id, movieId, movieName, movieName)}
          {renderSlideDetails(stars, endTimeSeconds, startTimeSeconds, sceneIndex)}
        </>
      );
    }
    return (
      <div className={'Scene'} key={`${movieId}${sceneIndex}`}>
        {view}
      </div>
    );
  };

  const renderSlideDetails = (stars, endTimeSeconds, startTimeSeconds, slideIndex) => {
    return (
      <div className="SlideDetails">
        <div className="Row">
          <div className="SceneNumbering">
            {t('MovieScenes.sceneIndex', {
              index: slideIndex < 10 ? `0${slideIndex}` : slideIndex,
            })}
            <div className="Duration">{`(00:${convertSecondsToTime(endTimeSeconds - startTimeSeconds)})`}</div>
          </div>
        </div>
        <div className="Row">
          <div className="Stars">
            <TruncateText data={stars} route={getStarDetailsRoute} />
          </div>
        </div>
      </div>
    );
  };

  return scenes.length > 1 ? <div className="MovieScenes">{scenes.map(renderScene)}</div> : null;
};

MovieScenes.propTypes = {
  scenes: PropTypes.array,
};

export default MovieScenes;
