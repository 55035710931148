import React, { useState } from 'react';
import PlaylistsPlayerPopup from '../PlaylistsPlayerPopup/PlaylistsPlayerPopup';
import { getAllPlaylists } from '../../../services/playlists-service/playlists.service';

import './CreateNewPlaylistsBtn.scss';

const CreateNewPlaylistsBtn = ({ refProps, updateParentState }) => {
  const [showPlaylistsPopup, setShowPlaylistsPopup] = useState(false);

  const renderCreatePlaylistsPopup = () => {
    if (!showPlaylistsPopup) return null;
    return (
      <PlaylistsPlayerPopup
        title="Create new playlist"
        ref={refProps}
        callback={() => {
          setShowPlaylistsPopup(false);

          getAllPlaylists()
            .then((resp = {}) => {
              const playlists = resp.data?.data || [];
              if (updateParentState) {
                updateParentState((prevState) => ({
                  ...prevState,
                  allPlaylists: playlists,
                }));
              }
            })
            .catch((err) => console.log(err));
        }}
        hideList
        create
        closePopup={() => setShowPlaylistsPopup(false)}
      />
    );
  };

  return (
    <span className="CreateNewPlaylistsBtn">
      <span
        className="CP-text"
        onClick={(e) => {
          e.stopPropagation();
          setShowPlaylistsPopup(!showPlaylistsPopup);
        }}
      >
        <i className={`fa fa-${showPlaylistsPopup ? 'minus' : 'plus'}`}></i> create new playlist
      </span>
      {renderCreatePlaylistsPopup()}
    </span>
  );
};

CreateNewPlaylistsBtn.displayName = 'CreateNewPlaylistsBtn';

export default CreateNewPlaylistsBtn;
