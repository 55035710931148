import React from 'react';

import StreamateLink from '../../../components/StreamateLink/StreamateLink.js';
import BannerSetViewFunc from '../../../components/BannerSetView/BannerSetViewFunc.jsx';
import { useHtmlContentContext } from '../../../context/HtmlContentContext.js';
import RenderHtmlContent from '../../../components/RenderHtmlContent/RenderHtmlContent.js';

const Streamate = () => {
  const { htmlContent } = useHtmlContentContext();

  return (
    <>
      <h2 style={{
        color: '#112d5d',
        fontSize: '2em',
        margin: '1em 0 0',
        textTransform: 'uppercase',
        textAlign: 'center'
      }}>Like to chat live?</h2>
      <StreamateLink
        iframeSrc='https://as.sexad.net/as/if?p=reseller&w=1&h=1&v=9933&adHeight=950&adWidth=890&niche=gay&adType=live&autoplay=false&showvideo=false&hn=nakedswordcasting.com&AFNO=1-1'
      />
      <RenderHtmlContent htmlContent={htmlContent} dataLabel="support_service" />
      <BannerSetViewFunc bannerName="tour" />
    </>
  );
};

Streamate.displayName = 'Streamate';

export default Streamate;
