import React, { useState, useEffect } from 'react';
import { FullPlayer } from '@falconstudios/ns-player';

import { useWLconfigContext } from '../../../context/WLConfigContext';
import ImageLoadingSkeleton from '../../../components/ImageLoadingSkeleton/ImageLoadingSkeleton';

import './SinglePlayer.scss';

export default function SinglePlayer(props) {
  const { src = '', poster = '', ...otherProps } = props;
  const { wl_playerConfig: { progressBarColors = {} } = {} } = useWLconfigContext();

  const [state, setState] = useState({ loading: true });
  const { loading } = state;

  useEffect(() => {
    setState({ loading: false });
  }, []);

  const renderPlayer = () => {
    if (loading) return <ImageLoadingSkeleton className='PlayerLoader' />;

    if (!src) return null;

    return (
      <FullPlayer data={{
        autoplay: false,
        progressBarColors: progressBarColors,
        videos: [{
          sources: [{
            src: src
          }],
          poster: poster || ''
        }],
        ...otherProps
      }} />
    )
  };

  return (
    <div className='SinglePlayer'>
      {renderPlayer()}
    </div>
  )
};
