import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthContext } from '../../context/AuthContext';
import { showApplicationStart } from '../../services/navigation/navigation.service';
import { isWeb } from '../../services/util-service/util.service';
import { getUserLocalData, setUserLocalData } from '../../services/users-service/users.service';

function StreamateLink(props) {
  const { iframeSrc } = props;
  const { userLoggedIn } = useAuthContext();
  const [state, setState] = useState({
    streamateLink: '',
    btnClicked: false,
    loading: false,
  });
  const {
    streamateLink,
    btnClicked,
    loading,
  } = state;

  useEffect(() => {
    if (!userLoggedIn) {
      showApplicationStart();
    }
  }, [userLoggedIn]);

  useEffect(() => {
    const userData = getUserLocalData();
    const userUrl = userData.streamate_oneclick_url;

    if (userUrl) {
      setState(prevState => ({ ...prevState, streamateLink: userUrl }));
    }
  }, []);

  const fetchUrl = () => {
    setState(prevState => ({ ...prevState, loading: true }));

    axios.get('/frontend/streamate/oneclick/url')
      .then(resp => {
        const newUrl = resp?.data?.data?.streamate_oneclick_url;

        if (newUrl) {
          const userData = getUserLocalData();
          userData.streamate_oneclick_url = newUrl;
          setUserLocalData(userData);
          window.location.href = newUrl;
        }
        setState(prevState => ({ ...prevState, loading: false }));
      })
      .catch(err => {
        console.log(err);
      })
  };

  const handleClick = () => {
    setState(prevState => ({ ...prevState, btnClicked: true }));

    if (streamateLink) {
      window.location.href = streamateLink;
    } else {
      fetchUrl();
    }
  };

  return (
    <>
      <img
        src="https://spotlight.nakedcdn.com/nakedsword/img/nakedsword-casting-logo.png"
        alt="nakedswordcasting.com"
        style={{
          display: 'block',
          margin: '1em auto',
          maxWidth: '350px',
          width: '100%',
        }}
      />

      <button
        onClick={() => handleClick()}
        style={{
          border: 'none',
          cursor: 'pointer',
          background: 'none',
          display: 'block',
          width: '100%',
          maxWidth: '65em',
          margin: '0 auto',
          position: 'relative'
        }}
        disabled={btnClicked ? true : false}
      >
        {loading && (
          <span style={{
            position: 'absolute',
            inset: 0,
            background: 'rgba(0,0,0,.3)',
            color: '#fff',
            borderRadius: '.2em',
            display: 'flex',
            fontSize: '4em'
          }}>
            <i className="fa fa-spinner fa-spin" style={{ margin: 'auto' }}></i>
          </span>
        )}
        <iframe
          src={iframeSrc}
          title="Cambuilde850x980"
          width="850"
          height="980"
          scrolling="no"
          style={{
            border: 'none',
            display: 'block',
            margin: '0 auto',
            overflow: 'hidden',
            pointerEvents: 'none',
            width: '100%',
            height: isWeb() ? '' : 'auto',
            maxHeight: '54em'
          }}
        ></iframe>
      </button>
    </>
  );
}

export default StreamateLink;
