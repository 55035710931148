import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';

import { getPlaylistsBannerSet } from '../../../services/banners-service/banners.service';
import { routes } from '../../../services/navigation/navigation.service.routes';

import PlaylistsMainView from './PlaylistsMainView/PlaylistsMainView';
import PlaylistMovies from './PlaylistMovies/PlaylistMovies';
import BannerSetViewFunc from '../../../components/BannerSetView/BannerSetViewFunc';

import './PlaylistsPage.scss';

const PlaylistsPage = () => {
  const [state, setState] = useState({
    playlistBanners: [],
    playlistBannersInfo: {},
    pagination: { page: 1, last_page: 1 },
    loading: true,
    error: false,
  });

  const {
    playlistBanners = [],
    playlistBannersInfo = {},
    pagination: { page, last_page },
    loading,
    error,
  } = state;

  useEffect(() => {
    getPlaylistsBannerSet(page)
      .then((resp) => {
        const { banners = [], banners_set = {}, pagination = {} } = resp.data.data || {};
        const { current_page, last_page } = pagination || {};

        setState((prevState) => ({
          ...prevState,
          playlistBanners: banners,
          playlistBannersInfo: banners_set,
          pagination: { page: current_page, last_page },
        }));
      })
      .catch((err) => {
        setState((prevState) => ({ ...prevState, error: true }));
        console.log(err);
      })
      .finally(() => setState((prevState) => ({ ...prevState, loading: false })));
  }, [page]);

  const onPageChange = (val) => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
      pagination: { ...prevState.pagination, page: val },
    }));
  };

  return (
    <div className="PlaylistsPage">
      <Router primary={false}>
        <PlaylistsMainView
          path={'/'}
          playlistBanners={playlistBanners}
          playlistBannersInfo={playlistBannersInfo}
          loading={loading}
          onPageChange={onPageChange}
          pagination={{ page, last_page }}
          error={error}
        />
        <PlaylistMovies dataKey="movies_in_playlist" path={routes.playlistMovies.replace(routes.playlists, '')} />
        <PlaylistMovies dataKey="scenes_in_playlist" path={routes.playlistScenes.replace(routes.playlists, '')} />
        <PlaylistMovies dataKey="movies" path={routes.playlistCustom.replace(routes.playlists, '')} />
      </Router>
      <BannerSetViewFunc bannerName="tour" />
    </div>
  );
};

PlaylistsPage.displayName = 'PlaylistsPage';

export default PlaylistsPage;
