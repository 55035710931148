import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImageLoadingSkeleton from '../../ImageLoadingSkeleton/ImageLoadingSkeleton';
import './AlphabeticViewLoadingSkeleton.scss';

class AlphabeticViewLoadingSkeleton extends Component {
  renderItems = (key) => {
    let items = [];
    let item = 0;
    while (item < 5) {
      items.push(<ImageLoadingSkeleton key={`${key}=${item}`} className="LoadingSkeleton" />);
      item++;
    }

    return items;
  };

  renderLetters = () => {
    let alphabets = [];
    let start = 'A'.charCodeAt(0);
    let last = 'Z'.charCodeAt(0);
    let letter;
    for (let i = start; i <= last; ++i) {
      letter = String.fromCharCode(i);
      alphabets.push(
        <div className="Letter" key={letter}>
          {letter}
        </div>,
      );
      alphabets.push(...this.renderItems(letter));
    }

    return alphabets;
  };

  render() {
    return <div className="AlphabeticViewLoadingSkeleton">{this.renderLetters()}</div>;
  }
}

AlphabeticViewLoadingSkeleton.propTypes = {
  contentWidth: PropTypes.number,
};

export default AlphabeticViewLoadingSkeleton;
