import React from 'react';
import SinglePlayer from '../SinglePlayer/SinglePlayer';

export default function WeekendsPage() {
  return (
    <>
      <SinglePlayer
        src='https://d34kds0xkdmtq8.cloudfront.net/BGP028.mp4'
        poster='https://spotlight.nakedcdn.com/nakedsword/img/ns_play/WNS_RentBoys_16x9.jpg'
      />

      <h2>RENT BOYS | WATCH FREE JANUARY 14-16</h2>
      <p>
        (German) Despised and suppressed to the fringe of society - this is the reality of male prostitutes in
        Berlin. Rosa von Praunheim acompanies them, telling stories and above all, their will to survive.
      </p>
    </>
  );
} 