import React, { useEffect, useState } from 'react';

import VideosPlaylist from '../../VideosPlaylist/VideosPlaylist';
import VideosPlaylistLoader from '../../VideosPlaylist/VideosPlaylistLoader';
import { ErrorMessage } from '../../../components/ErrorBoundary/ErrorBoundary';

import { useAuthContext } from '../../../context/AuthContext';
import { getUserFavorites, reorderFavoritesItems } from '../../../services/my-account-service/my-account.service';
import { showApplicationStart } from '../../../services/navigation/navigation.service';

import './FavoritesPage.scss';

const FavoritesPage = () => {
  const { userLoggedIn } = useAuthContext();

  const [state, setState] = useState({
    videos: [],
    pagination: { page: 1, last_page: 1, totalVideos: 0 },
    loading: true,
    nextPageLoading: false,
    error: false,
  });

  const { videos = [], pagination: { page, last_page, totalVideos } = {}, loading, nextPageLoading, error } = state;

  useEffect(() => {
    if (!userLoggedIn) {
      showApplicationStart();
      return;
    };

    getUserFavorites(page)
      .then((resp = {}) => {
        const { favorites = [], pagination = {} } = resp.data?.data || {};
        const { current_page, last_page, total } = pagination || {};

        setState((prevState) => ({
          ...prevState,
          videos: [...prevState.videos, ...favorites],
          pagination: { page: current_page, last_page, totalVideos: total },
        }));
      })
      .catch((err) => {
        setState((prevState) => ({ ...prevState, error: true }));
        console.log(err);
      })
      .finally(() => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          nextPageLoading: false,
        }));
      });
  }, [page, userLoggedIn]);

  const onPageChange = (val) => {
    setState((prevState) => ({
      ...prevState,
      nextPageLoading: true,
      pagination: { ...prevState.pagination, page: val },
    }));
  };

  if (error) return <ErrorMessage />;

  return userLoggedIn ? (
    <div className="FavoritesPage">
      {loading && <VideosPlaylistLoader />}

      <VideosPlaylist
        videos={videos}
        totalVideos={totalVideos}
        loading={loading}
        currentPlaylist={{ id: '', name: 'My Favorites' }}
        onPageChange={onPageChange}
        page={page}
        lastPage={last_page}
        nextPageLoading={nextPageLoading}
        reorderFn={reorderFavoritesItems}
        updateFavoritesPageState={setState}
        hideEditBtns
      />
    </div>
  ) : null;
};

FavoritesPage.displayName = 'FavoritesPage';

export default FavoritesPage;
