import React from 'react';
import { isWeb } from '../../../services/util-service/util.service';

const F4FLive = ({ iframeSrc }) => {
  return (
    <>
      <img
        src="https://spotlight.nakedcdn.com/nakedsword/img/nakedswrordlive-logo.png"
        alt="nakedswordlive.com"
        style={{
          display: 'block',
          width: '100%',
          maxWidth: '300px',
          margin: '1em auto'
        }}
      />
      <iframe
        src={iframeSrc}
        title="LiveIframe"
        width="850"
        height="980"
        scrolling="no"
        style={{
          border: 'none',
          display: 'block',
          margin: '0 auto',
          overflow: 'hidden',
          pointerEvents: 'none',
          width: '100%',
          height: isWeb() ? '' : 'auto',
          maxWidth: '45em',
          maxHeight: '50em'
        }}
      ></iframe>
    </>
  );
};

export default F4FLive;
