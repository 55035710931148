import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import MyAccountUserInfos from '../MyAccountUserInfos/MyAccountUserInfos';
import MySection from '../MySection/MySection';
import { routes } from '../../../../services/navigation/navigation.service.routes';
import BannerSetViewFunc from '../../../../components/BannerSetView/BannerSetViewFunc';
import { isWeb } from '../../../../services/util-service/util.service';
import DealsImport from '../../MemberDealsPage/DealsImport';
import MyAccountSectionHeader from '../MyAccountSectionHeader/MyAccountSectionHeader';
import './MyAccountWeb.scss';

const MyAccountWeb = (props) => {
  const { data, loading, sections = [], updateParentState } = props;
  const infosRef = useRef();

  useEffect(() => {
    const nav = infosRef.current;

    if (nav && isWeb()) {
      const header = document.querySelector('.MainDashboardHeader');

      if (header) {
        nav.style.top = `${header.offsetTop + header.offsetHeight}px`;
      }
    }
  }, []);

  const renderSectionElement = (index, children) => {
    return <div id={sections[index]?.key}>{children}</div>;
  };

  const renderUserInfos = () => {
    const { userData } = data;
    return <MyAccountUserInfos email={userData.email} />;
  };

  const scrollToSection = (key) => {
    if (loading) return;

    const offsetTop = document.querySelector(`#${key}`)?.offsetTop;

    if (offsetTop) {
      const header = document.querySelector('.MainDashboardHeader');
      if (header) {
        scrollIntoView(offsetTop - header.offsetHeight - 80);
      }
    }
  };

  const scrollIntoView = (offsetTop) => {
    document.getElementsByClassName('MainContent')[0].scrollTop = offsetTop;
  };

  const sectionMap = ({ key, name }) => {
    return (
      <span className="NavigationSection" key={name} onClick={scrollToSection.bind(this, key)}>
        {name}
      </span>
    );
  };

  return (
    <div className="MyAccountWeb">
      {!isWeb() && <BannerSetViewFunc bannerName="promo" />}
      <div className="Infos" ref={infosRef}>
        {isWeb() && (
          <>
            <div>{sections.map(sectionMap)}</div>
            {loading && <i className="fa fa-spinner fa-spin" style={{ fontSize: '130%' }}></i>}{' '}
          </>
        )}
        {renderUserInfos()}
      </div>

      {isWeb() && <BannerSetViewFunc bannerName="promo" />}

      {renderSectionElement(
        0,
        <MySection noRecords={'noFavorites'} data={data.favorites} route={routes.favorites} loading={loading} />,
      )}

      {renderSectionElement(3, <>
        <MyAccountSectionHeader
          actions={[
            {
              isExpandable: false,
              onClick: () => navigate(`/${routes.memberDeals}`),
              text: 'Show All',
              allowed: true
            },
          ]}
          title='My deals'
        />
        <DealsImport numberOfDisplayedOffers={2} />
      </>
      )}

      {renderSectionElement(
        1,
        <MySection
          noRecords={'noUserPlaylists'}
          data={data.allPlaylists}
          route={routes.userPlaylists}
          loading={loading}
          updateParentState={updateParentState}
        />,
      )}
      {renderSectionElement(
        2,
        <MySection
          noRecords={'noHistory'}
          data={data.viewingHistory}
          route={routes.viewingHistory}
          loading={loading}
        />,
      )}
    </div>
  );
};

MyAccountWeb.propTypes = {
  data: PropTypes.object,
  sections: PropTypes.array,
  loading: PropTypes.bool,
  updateParentState: PropTypes.func,
};

export default MyAccountWeb;
