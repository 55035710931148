import React, { useEffect, useState, useRef, useCallback } from 'react';
import { navigate } from '@reach/router';

import { searchLandingResults } from '../../../services/search-service/search.service';

import BannerSetViewFunc from '../../../components/BannerSetView/BannerSetViewFunc';
import PageIndicator from '../../../components/PageIndicator/PageIndicator';
import MoviesScenesGrid from '../../../components/MoviesScenesGrid/MoviesScenesGrid';

import { SecondaryNavigation, getSortByOptions, navigationOptions } from '../../../constants/navigation';
import { handleHistory } from '../../../hook/useRouteChange';

import { useAuthContext } from '../../../context/AuthContext';
import './SearchResultsPage.scss';

// display movies for this values
const searchDropdownOptions = {
  stars: 'stars',
  directors: 'directors',
  studios: 'studios',
};

const SearchResultsPage = (props) => {
  const { searchDropdownValue = '', location = {} } = props;
  const searchTerm = new URLSearchParams(location.search).get('q') || '';
  const { userLoggedIn } = useAuthContext();

  const selectSearchDropdownValue = useCallback(() => {
    let value = searchDropdownValue;

    switch (searchDropdownValue) {
      case searchDropdownOptions.stars:
      case searchDropdownOptions.directors:
      case searchDropdownOptions.studios:
        value = navigationOptions.movies;
        break;
      default:
        value = navigationOptions.scenes;
    }
    return value;
  }, [searchDropdownValue]);

  const [state, setState] = useState({
    query: searchTerm,
    object: selectSearchDropdownValue(),
    queryObject: searchDropdownValue,
    videos: [],
    sort: location.state?.sort || SecondaryNavigation.Relevance,
    type: selectSearchDropdownValue() || navigationOptions.scenes,
    pagination: { page: location.state?.page || 1, last_page: 1 },
    loading: true,
    error: false,
    title: 'please wait...',
  });

  const {
    pagination: { page, last_page } = {},
    sort,
    type,
    object,
    queryObject = '',
    query = '',
    loading,
    error,
    videos = [],
    title,
  } = state;

  let pushToHistoryRef = useRef(false);

  const handleError = (err) => {
    console.log(err);
    setState((prevState) => ({ ...prevState, error: true }));
  };

  const handleLoading = (val) => setState((prevState) => ({ ...prevState, loading: val }));

  useEffect(() => {
    return () => setState({});
  }, []);

  useEffect(() => {
    setState({
      query: searchTerm,
      object: selectSearchDropdownValue(),
      queryObject: searchDropdownValue,
      videos: [],
      sort: SecondaryNavigation.Relevance,
      type: selectSearchDropdownValue(),
      pagination: { page: 1, last_page: 1 },
      loading: true,
      error: false,
      title: 'please wait...',
    });
    // eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {
    handleHistory(
      { page, sort, type },
      (page, sort, type) => {
        setState((prevState) => ({
          ...prevState,
          pagination: { ...prevState.pagination, page },
          sort,
          type,
        }));
      },
      pushToHistoryRef,
    );

    setState((prevState) => ({
      ...prevState,
      videos: [],
      loading: true,
      title: 'please wait...',
    }));

    const { result } = searchLandingResults({
      query,
      page,
      sortby: sort,
      type: object,
      queryObject,
    });

    result
      .then((resp = {}) => {
        const { query_string = '', pagination = {}, scenes = [], movies = [] } = resp.data?.data;
        const { current_page, last_page } = pagination;
        const videos = scenes.length !== 0 ? scenes : movies;

        setState((prevState) => ({
          ...prevState,
          videos,
          pagination: { page: current_page, last_page },
          title: query_string || 'term not found',
        }));
      })
      .catch(handleError)
      .finally(() => handleLoading(false));
  }, [page, sort, type, query, object, queryObject]);

  const onPageChange = (val) => {
    setState((prevState) => ({
      ...prevState,
      videos: [],
      pagination: { ...prevState.pagination, page: val },
    }));
  };

  const onTypeChange = (val) => {
    setState((prevState) => ({
      ...prevState,
      videos: [],
      type: val,
      object: val,
      sort: SecondaryNavigation.Relevance,
      pagination: { page: 1, last_page: 1 },
    }));
  };

  const onSortByClick = (val) => {
    setState((prevState) => ({
      ...prevState,
      videos: [],
      sort: val,
      pagination: { page: 1, last_page: 1 },
    }));
  };

  const renderPagination = () => {
    if (page === 1 && last_page === 1) return null;
    if (error) return null;
    return (
      <PageIndicator
        page={page}
        pageCount={last_page}
        onPageChange={onPageChange}
        scrollIntoView=".ShowMeSortByNavigation"
      />
    );
  };

  const sortOptions = () => {
    const defaultOptions = getSortByOptions();
    const alteredOptions = defaultOptions.map((item) => {
      if (item.name === navigationOptions.newest) {
        item.name = navigationOptions.relevance;
        item.value = SecondaryNavigation.Relevance;
      }
      return item;
    });

    return alteredOptions;
  };

  return (
    <div className="SearchResultsPage">
      <MoviesScenesGrid
        data={videos}
        loading={loading}
        type={type}
        sort={sort}
        sortByOptions={sortOptions()}
        onShowMeClick={onTypeChange}
        onSortByClick={onSortByClick}
        error={error}
        title={title}
      />
      {renderPagination()}
      {!userLoggedIn && <BannerSetViewFunc bannerName="whatMembershipBringsYou" onClick={() => navigate('/join')} />}
    </div>
  );
};

SearchResultsPage.displayName = 'SearchResultsPage';

export default SearchResultsPage;
