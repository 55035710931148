import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getMoviePlaylist, getScenePlaylist } from '../../../../services/playlists-service/playlists.service';

import VideosPlaylistLoader from '../../../VideosPlaylist/VideosPlaylistLoader';
import VideosPlaylist from '../../../VideosPlaylist/VideosPlaylist';
import { ErrorMessage } from '../../../../components/ErrorBoundary/ErrorBoundary';
import SlickSlider from '../../../../components/SlickSlider/SlickSlider';

import './PlaylistMovies.scss';

const PlaylistMovies = (props) => {
  const { dataKey, moviePlaylistId, scenePlaylistId } = props;

  const [state, setState] = useState({
    videos: [],
    banner: {},
    playlistInfo: { id: '', name: '' },
    loading: true,
    error: false,
  });

  const { videos = [], banner = {}, playlistInfo = {}, loading, error } = state;

  useEffect(() => {
    const getDataFunc = () => {
      return scenePlaylistId ? getScenePlaylist(scenePlaylistId) : getMoviePlaylist(moviePlaylistId);
    };

    getDataFunc()
      .then((resp = {}) => {
        const banner = resp.data?.data?.banner || {};
        const videos = resp.data?.data[dataKey] || [];
        const { id = '', name = '' } = resp.data?.data || {};

        setState((prevState) => ({
          ...prevState,
          videos,
          banner,
          playlistInfo: { id, name },
        }));
      })
      .catch((err) => {
        setState((prevState) => ({ ...prevState, error: true }));
        console.log(err);
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, loading: false }));
      });
  }, [dataKey, moviePlaylistId, scenePlaylistId]);

  const renderPlaylistBanner = () => {
    return <SlickSlider slides={{ data: banner ? [banner] : [], info: {}, loading: loading }} />;
  };

  if (error) return <ErrorMessage />;

  return (
    <div className="PlaylistMovies">
      {renderPlaylistBanner()}

      {loading && <VideosPlaylistLoader />}

      <VideosPlaylist
        videos={videos}
        loading={loading}
        currentPlaylist={{ id: '', name: playlistInfo.name }}
        hideEditBtns
        hidePlaylistControls
      />
    </div>
  );
};

PlaylistMovies.propTypes = {
  moviePlaylistId: PropTypes.string,
  playlistName: PropTypes.string,
  scenePlaylistId: PropTypes.string,
};

export default PlaylistMovies;
