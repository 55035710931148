import React from 'react';
import ImageLoadingSkeleton from '../../components/ImageLoadingSkeleton/ImageLoadingSkeleton';

const VideosPlaylistLoader = () => {
  return (
    <>
      <ImageLoadingSkeleton className="VP-CarouselLoader" />
      <ImageLoadingSkeleton className="VP-TitleLoader" />
      <ImageLoadingSkeleton className="VP-ExternalPlayerlLoader" />
    </>
  );
};

VideosPlaylistLoader.displayName = 'VideosPlaylistLoader';

export default VideosPlaylistLoader;
