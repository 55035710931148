import React from 'react';

import SinglePlayer from '../../SinglePlayer/SinglePlayer';

export default function Free() {
  return (
    <>
      <SinglePlayer
        src='https://d34kds0xkdmtq8.cloudfront.net/nakedsword-free-gift.mp4'
        poster='https://spotlight.nakedcdn.com/nakedsword/img/free/nakedsword_free_gift_poster_16x9.jpg'
      />

      <h4 style={{
        fontSize: '120%',
        margin: '1em 0'
      }}>
        THE SWORDS: FINAL CUT
      </h4>

      <p>
        The ultimate showdown between good and evil is at the center of ‘The Swords: Final Cut’, a high-octane
        thrill-ride that pushes the physical limits and sexual boundaries of an all-star cast including Exclusive
        Cole Connor, Reese Rideout, Sean Xavier, Roman Todd, Danny Starr, Sage Roux, Sean Zevran, and the return
        of super stud Andrew Stark. In this final installment of the multi-part saga, the agents are forced into
        hiding deep inside a desolate desert cave when their real identities become dangerously public. It’s not
        long until their hideout is discovered, and a sinister plan to eliminate them is set into motion. Will The
        Swords endure their deadliest mission yet? If there’s even a chance, it’ll take everything they’ve got —
        and perhaps a secret, game-changing weapon from the past — to survive another day.
      </p>
      <p>
        Watch the full trailer now, and catch the world premiere of the first episode August 2nd, exclusively on
        NakedSword.
      </p>
    </>
  )
};