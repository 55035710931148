import { authRefresh } from '../auth-service/auth.service';
import { cleanup } from '../../axios-interceptor';

export const TOKEN = 'fe-token';
export const EXPIRES = 'fe-expires';
export const REFRESH_TIMEOUT_ID = 'refreshTimeoutId';
const YULI = 'yuli';

const refresh = {
  promise: null,
};

export const cleanupRefreshTimeout = () => {
  const refreshTimeoutId = localStorage.getItem(REFRESH_TIMEOUT_ID);
  if (refreshTimeoutId !== undefined) {
    clearTimeout(parseInt(refreshTimeoutId));
    localStorage.removeItem(REFRESH_TIMEOUT_ID);
  }
};

/**
 * Removes token and token expiration values from localStorage
 */
export const cleanupToken = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(EXPIRES);
  setYuliCookie('', 'Thu, 01 Jan 1970 00:00:00 GMT');
  cleanupRefreshTimeout();
};

export const minutesToMiliseconds = (time) => time * 60000;

/**
 * Token can't be refreshed once it expires so we need to refresh it before that time
 * This constant represents minimal time that needs to be subtracted from expiration for refresh timeout
 * @returns {number}
 */
export const REFRESH_DENOMINATOR = minutesToMiliseconds(10); //10 minutes is the time before token expires when we refresh token

/**
 * Returns a reference to the token refresh http request promise
 *
 * @returns {Promise|null}
 */
export const getTokenRefreshPromise = () => {
  return refresh.promise;
};

/**
 * Returns token from localStorage
 *
 * @returns {string}
 */
export const getUserToken = () => {
  return localStorage.getItem(TOKEN);
};

/**
 * Returns token expiration time from localStorage
 * NOTE: expiration returned is the time when token expires, not the duration of the token
 *
 * @returns {string}
 */
export const getUserTokenExpiration = () => {
  return localStorage.getItem(EXPIRES);
};

/**
 * Returns true if token has expired, false otherwise
 *
 * @returns {boolean}
 */
export const hasTokenExpired = () => {
  const expires = getUserTokenExpiration();
  return !!(expires && expires < Date.now());
};

export const isUserLoggedIn = () => !!(getUserToken() && !hasTokenExpired());

/**
 * Saves token and token expiration to localStorage
 * Also sets up token refresh logic
 *
 * @param {string} token
 * @param {number} tokenDuration Token duration in minutes
 */
export const saveToken = (token, tokenDuration) => {
  localStorage.setItem(TOKEN, token);
  localStorage.setItem(EXPIRES, (Date.now() + minutesToMiliseconds(tokenDuration)).toString());
  setupTokenRefresh(tokenDuration);
};

/**
 * Token refresh success handler
 * Saves token and clear refresh promise reference
 *
 * @param {Object} response
 */
export const onTokenRefreshSuccess = (response) => {
  const { jwt, expiresIn } = response.data.data;
  saveToken(jwt, expiresIn);
  const yuliCookie = getYuliCookie();
  if (yuliCookie && typeof yuliCookie === 'object') {
    yuliCookie[EXPIRES] = expiresIn;
    saveYuliCookie(yuliCookie);
  }
  refresh.promise = null;
};

/**
 * Triggers token refresh
 */
/* istanbul ignore next */
export const refreshToken = () => {
  if (authRefresh) {
    authRefresh().then(onTokenRefreshSuccess).catch(cleanup);
  } else {
    cleanup();
  }
};

/**
 * Sets token refresh timeout
 *
 * @param {number} tokenDuration Token duration in minutes
 */
export const setupTokenRefresh = (tokenDuration) => {
  cleanupRefreshTimeout();
  const refreshDelay = minutesToMiliseconds(tokenDuration) - REFRESH_DENOMINATOR;
  let refreshTimeoutId = setTimeout(refreshToken, refreshDelay);
  localStorage.setItem(REFRESH_TIMEOUT_ID, refreshTimeoutId.toString());
};

export const handleTokenRefresh = () => {
  /**
   * Reloading application prevents all setTimeouts from executing so this is needed to facilitate token refresh
   * We clean previous setTimeout tokens in setupTokenRefresh
   */
  const userToken = getUserToken();
  if (userToken && !hasTokenExpired()) {
    const expires = getUserTokenExpiration();
    const now = Date.now();
    if (expires - REFRESH_DENOMINATOR < now) {
      // Token is about to expire so refresh immediately
      refreshToken();
    } else {
      const duration = Math.round((expires - now) / 60 / 1000);
      setupTokenRefresh(duration);
    }
  }
};

handleTokenRefresh();

// for Yoti age verification
const setYuliCookie = (data, expires) => {
  document.cookie = `${YULI}=${data}; expires=${expires}; path=/;`;
};

const getYuliCookie = () => {
  const yuliCookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${YULI}=`))
    ?.split('=')[1];
  let cookie = '';

  if (!yuliCookie) return cookie;

  try {
    cookie = JSON.parse(atob(yuliCookie));
  } catch (err) {
    console.log(err, 'Decoding failed!');
  }

  return cookie;
};

export const saveYuliCookie = (data) => {
  let userData = '';
  const respExpiresIn = data?.[EXPIRES] ? +data?.[EXPIRES] : 0; // usually 60
  const cookieExpires = new Date(Date.now() + respExpiresIn * 60000).toUTCString();
  // convert to timestamp
  data[EXPIRES] = (Date.now() + minutesToMiliseconds(respExpiresIn)).toString();

  try {
    userData = btoa(JSON.stringify(data));
  } catch (err) {
    console.log(err, 'Encoding failed!');
  }

  setYuliCookie(userData, cookieExpires);
};
