import React, { useEffect, useRef, useState } from 'react';
import { useAuthContext } from '../../../context/AuthContext';
import { showApplicationStart } from '../../../services/navigation/navigation.service';
import { getUserLocalData } from '../../../services/users-service/users.service';
import { getUserFavorites, getUserViewingHistory } from '../../../services/my-account-service/my-account.service';
import { getAllPlaylists } from '../../../services/playlists-service/playlists.service';
import MyAccountWeb from './MyAccountWeb/MyAccountWeb';

import BannerSetViewFunc from '../../../components/BannerSetView/BannerSetViewFunc';
import { routes } from '../../../services/navigation/navigation.service.routes';

import { isWeb } from '../../../services/util-service/util.service';
import { useHtmlContentContext } from '../../../context/HtmlContentContext';
import RenderHtmlContent from '../../../components/RenderHtmlContent/RenderHtmlContent';
import './MyAccountPage.scss';

const MyAccountPage = () => {
  const { htmlContent } = useHtmlContentContext();
  const { userLoggedIn } = useAuthContext();
  const mainRef = useRef();

  const sections = [
    {
      key: 'favorites',
      name: 'My Favorites',
      route: routes.favorites,
    },
    {
      key: 'userPlaylists',
      name: 'My Playlist',
      route: routes.userPlaylists,
    },
    {
      key: 'viewingHistory',
      name: 'Viewing History',
      route: routes.viewingHistory,
    },
    {
      key: 'myDeals',
      name: 'My Deals',
      route: routes.memberDeals,
    },
  ];

  const [state, setState] = useState({
    //benefits: {},
    favorites: [],
    allPlaylists: [],
    userData: getUserLocalData() || {},
    viewingHistory: [],
    loading: true,
  });

  useEffect(() => {
    if (!userLoggedIn) {
      showApplicationStart();
      return;
    };

    const promises = [
      getUserFavorites(1).catch((err) => console.log(err)),
      getAllPlaylists().catch((err) => console.log(err)),
      getUserViewingHistory(1).catch((err) => console.log(err)),
      // If there's data fetching needed for "My Deals", add the promise here
    ];

    Promise.all(promises)
      .then(loadData)
      .finally(() => {
        setState((prev) => ({
          ...prev,
          loading: false,
        }));
      });

  }, [userLoggedIn]);

  const loadData = (resp = []) => {
    const favorites = resp[0]?.data?.data?.favorites || [];
    const allPlaylists = resp[1]?.data?.data || [];
    const viewingHistory = resp[2]?.data?.data?.clicks || [];
    //const benefits = resp[3]?.data?.data || {};

    const newState = {
      favorites: isWeb() ? favorites.slice(0, 2) : favorites,
      allPlaylists,
      viewingHistory: isWeb() ? viewingHistory.slice(0, 2) : viewingHistory,
      //benefits
    };

    setState((prev) => ({
      ...prev,
      ...newState,
      loading: false,
    }));
  };

  const data = {
    favorites: state.favorites,
    allPlaylists: state.allPlaylists,
    viewingHistory: state.viewingHistory,
    userData: state.userData,
    //benefits: state.benefits
  };

  return (
    <div className="MyAccountPage" ref={mainRef}>
      <MyAccountWeb sections={sections} data={data} loading={state.loading} updateParentState={setState} />
      <RenderHtmlContent htmlContent={htmlContent} dataLabel="support_service" />
      <BannerSetViewFunc bannerName="tour" />
    </div>
  );
};

MyAccountPage.displayName = 'MyAccountPage';
export default MyAccountPage;
