import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';

import {
  getMoviePlaylistRoute,
  getScenePlaylistRoute,
} from '../../../../services/navigation/navigation.service.routes';
import { getImageByWidth } from '../../../../services/images-service/images.service';

import PageIndicator from '../../../../components/PageIndicator/PageIndicator';
import ImageLoaderComponent from '../../../../components/ImageLoaderComponent/ImageLoaderComponent';
import ImageLoadingSkeleton from '../../../../components/ImageLoadingSkeleton/ImageLoadingSkeleton';
import BaseSectionHeader from '../../../../components/BaseSectionHeader/BaseSectionHeader';
import BannerSetViewFunc from '../../../../components/BannerSetView/BannerSetViewFunc';

import './PlaylistsMainView.scss';

const PlaylistsMainView = (props) => {
  const { playlistBanners = [], playlistBannersInfo = {}, loading, error, onPageChange, pagination = {} } = props;

  const generatePlaylistRouteRn = ({ movie_playlists_id, scene_playlists_id, relevant_name }) => {
    const playlistName = (relevant_name || '').replace(/[^A-Za-z0-9]/g, '-');
    return movie_playlists_id
      ? getMoviePlaylistRoute(movie_playlists_id, playlistName)
      : getScenePlaylistRoute(scene_playlists_id, playlistName);
  };

  const renderBanners = () => {
    let view = null;

    if (loading) {
      let placeholders = [];
      for (let i = 0; i < 10; i++) {
        placeholders.push(<ImageLoadingSkeleton key={i} className="PL-ItemLoaders" />);
      }
      view = placeholders;
    }

    if (!loading && playlistBanners.length !== 0) {
      view = (
        <>
          {playlistBanners.map((item) => {
            const playlistImg = getImageByWidth(item.image, window.innerWidth / 1.5) || {};

            return (
              <Link to={generatePlaylistRouteRn(item)} key={item.id}>
                <ImageLoaderComponent url={playlistImg.url} alt={playlistImg.alt} />
              </Link>
            );
          })}
        </>
      );
    }

    if (error) return null;

    return (
      <div className="PL-Container">
        <BaseSectionHeader primaryText="Playlists" />
        <div
          className="PL-Grid"
          data-info={`${playlistBannersInfo.id} (${playlistBannersInfo.group}) ${playlistBannersInfo.name}`}
        >
          {view}
        </div>
      </div>
    );
  };

  return (
    <div className={'PlaylistsMainView'}>
      <BannerSetViewFunc bannerName="playlistsHeader" />
      <BannerSetViewFunc bannerName="promo" />

      {renderBanners()}

      {pagination.last_page > 1 ? (
        <PageIndicator page={pagination.page} pageCount={pagination.last_page} onPageChange={onPageChange} />
      ) : null}
    </div>
  );
};

PlaylistsMainView.propTypes = {
  playlistBanners: PropTypes.array,
  playlistBannersInfo: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  onPageChange: PropTypes.func,
  pagination: PropTypes.object,
};

PlaylistsMainView.displayName = 'PlaylistsMainView';

export default PlaylistsMainView;
