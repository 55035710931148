import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';

import MyAccountSectionHeader from '../MyAccountSectionHeader/MyAccountSectionHeader';

import { routes } from '../../../../services/navigation/navigation.service.routes';
import { isWeb } from '../../../../services/util-service/util.service';

import MoviesScenesGrid from '../../../../components/MoviesScenesGrid/MoviesScenesGrid';
import GridPlaylist from '../../../../components/PlaylistComponents/GridPlaylist/GridPlaylist';
import CreateNewPlaylistsBtn from '../../../../components/PlaylistComponents/CreateNewPlaylistsBtn/CreateNewPlaylistsBtn';

import './MySection.scss';

const MySection = ({ data = [], route, noRecords, loading, updateParentState }) => {
  const { t } = useTranslation();
  const [toggleOpen, setToggleOpen] = useState(false);

  const getTitle = () => {
    switch (route) {
      case routes.favorites:
        return t('MySection.myFavorites');
      case routes.userPlaylists:
        return (
          <>
            <span>{t('MySection.userPlaylists')}</span>
            {isWeb() && <CreateNewPlaylistsBtn updateParentState={updateParentState} />}
          </>
        );
      case routes.viewingHistory:
        return t('MySection.viewingHistory');
      default:
        return '';
    }
  };

  const showAll = () => navigate(`/${route}`);

  const renderRow = () => {
    let view = null;
    const isPlaylist = routes.userPlaylists === route;

    if (isPlaylist) {
      view = (
        <>
          {!isWeb() && <CreateNewPlaylistsBtn updateParentState={updateParentState} />}
          <GridPlaylist
            allPlaylists={data}
            loading={loading}
            updateParentState={updateParentState}
            sliceList={3}
            loadingItems={3}
          />
        </>
      );
    } else {
      view = <MoviesScenesGrid data={data} loading={loading} hideNavigation />;
    }

    if (!loading && data.length === 0) {
      view = (
        <div className={`NoRecords ${isPlaylist ? 'PlaylistNoRecords' : ''}`}>
          <div>
            {!isPlaylist && <div>{t(`MySection.${noRecords}`)}</div>}
            {isPlaylist && (
              <>
                {isWeb() && <div>{t(`MySection.${noRecords}`)}</div>}
                <span className="NR-Subtitle">
                  to add scenes or movies to your playlist
                  {!isWeb() && <br />} select the <i className="fa fa-plus-circle"></i> symbol on the player
                </span>
              </>
            )}
          </div>
        </div>
      );
    }

    return <div className={`SectionOpen ${toggleOpen ? 'Active' : ''}`}>{view}</div>;
  };

  return (
    <div className="MySection" onClick={() => (!isWeb() ? setToggleOpen(!toggleOpen) : null)}>
      <MyAccountSectionHeader
        actions={[
          {
            isExpandable: false,
            onClick: showAll,
            text: t('MySection.showAll'),
            allowed: !loading && data.length,
          },
        ]}
        title={getTitle()}
      />
      {renderRow()}
    </div>
  );
};

MySection.propTypes = {
  data: PropTypes.array,
  route: PropTypes.string.isRequired,
  noRecords: PropTypes.string,
  loading: PropTypes.bool,
  updateParentState: PropTypes.func,
};

export default MySection;
