import React, { Suspense } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';

import LoadingMask, { LoadingText } from './components/LoadingMask/LoadingMask';
import MainDashboard from './pages/MainDashBoard/MainDashboard';
import { MiniPlayerContainer } from '@falconstudios/ns-player';

import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/fantasy/index.css';
import Div100vh from 'react-div-100vh';
import { cleanupToken, hasTokenExpired } from './services/token-service/token.service';

import './styles/main.scss';
import './App.scss';
import './overrides/overrides.css';
import ImagesLightBoxStyle from './components/ImagesLighbox/ImagesLightBoxStyle';
import { HtmlContentContextProvider } from './context/HtmlContentContext';
import { WLConfigContextProvider } from './context/WLConfigContext';
import { AuthContextProvider } from './context/AuthContext';

function App() {
  if (hasTokenExpired()) {
    cleanupToken();
  }

  return (
    <Div100vh>
      <div className="App">
        <Suspense fallback={<LoadingText />}>
          <WLConfigContextProvider>
            <AuthContextProvider>
              <HtmlContentContextProvider>
                <MainDashboard />
              </HtmlContentContextProvider>
            </AuthContextProvider>
          </WLConfigContextProvider>
        </Suspense>
        <MiniPlayerContainer />
        <LoadingMask /> {/* log in / out*/}
        <ImagesLightBoxStyle /> {/* MovieDetailsPage image gallery (fas fa-camera icon) */}
      </div>
    </Div100vh>
  );
}

export default App;
