import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { navigate, useLocation } from '@reach/router';

import ImageLoaderComponent from '../../ImageLoaderComponent/ImageLoaderComponent';
import PlaylistsPlayerPopup, { PLAYLIST_ACTION } from '../PlaylistsPlayerPopup/PlaylistsPlayerPopup';
import ImageLoadingSkeleton from '../../ImageLoadingSkeleton/ImageLoadingSkeleton';
import CreateNewPlaylistsBtn from '../CreateNewPlaylistsBtn/CreateNewPlaylistsBtn';
import BaseSectionHeader from '../../BaseSectionHeader/BaseSectionHeader';

import { setContentScrolltop } from '../../../services/util-service/util.service';
import { routes } from '../../../services/navigation/navigation.service.routes';

import './GridPlaylist.scss';

const GridPlaylist = (props) => {
  const {
    allPlaylists = [],
    loading,
    updateParentState,
    sliceList,
    loadingItems = 10,
    currentPlaylistId,
    title,
    onPlaylistClickProps,
  } = props;

  const [activePopup, setActivePopup] = useState({});
  const [clickedPlaylist, setClickedPlaylist] = useState(null);
  const playlistsPlayerPopupRef = useRef(null);
  const { pathname } = useLocation();

  // close popup on playlist change
  useEffect(() => {
    setActivePopup({});
    setClickedPlaylist(null);
  }, [currentPlaylistId]);

  const renderPlaylistsPopup = (id) => {
    if (!activePopup[id]) return null;
    const selected = allPlaylists.find((item) => +item.id === +Object.keys(activePopup)[0]) || {};

    return (
      <PlaylistsPlayerPopup
        ref={playlistsPlayerPopupRef}
        title="Update playlist"
        closePopup={() => setActivePopup({})}
        callback={({ name, action }) => {
          setActivePopup({});

          if (updateParentState) {
            let playlists;

            if (action === PLAYLIST_ACTION.update) {
              playlists = allPlaylists.map((playlist) => {
                if (playlist.id === id) playlist.name = name;
                return playlist;
              });
            } else if (action === PLAYLIST_ACTION.delete) {
              playlists = allPlaylists.filter((playlist) => playlist.id !== id);
            }

            // from UserPlaylistsPage.js and MyAccountPage.js
            updateParentState((prevState) => ({
              ...prevState,
              currentPlaylist: {
                ...prevState.currentPlaylist,
                name,
              },
              allPlaylists: playlists,
            }));
          }
        }}
        selectedPlaylist={selected}
        hideList
        update
      />
    );
  };

  const onPlaylistClick = (id) => {
    const activePlaylist = allPlaylists.find((item) => item.id === id) || {};

    if (activePlaylist.contents_ids?.length === 0) return;

    // prevents double fetch in UserPlaylistsPage.js in fetchSinglePlaylist
    if (pathname !== `/${routes.userPlaylists}`) {
      navigate(`/${routes.userPlaylists}`, { state: { playlistId: id } });
    }

    if (onPlaylistClickProps) {
      setClickedPlaylist(id);
      setContentScrolltop();
      onPlaylistClickProps(id);
    }
  };

  const renderLoadingSkeletons = () => {
    const placeholders = [];
    let i = 0;
    for (i; i < loadingItems; i++) {
      placeholders.push(
        <div key={i}>
          <ImageLoadingSkeleton className="GP-PlaceholderImage" />
          <ImageLoadingSkeleton className="GP-PlaceholderText" />
        </div>,
      );
    }
    return placeholders;
  };

  const renderGrid = () => {
    let view = null;

    if (loading) view = renderLoadingSkeletons();

    if (Array.isArray(allPlaylists) && allPlaylists.length !== 0 && !loading) {
      const playlists = sliceList ? allPlaylists.slice(0, sliceList) : allPlaylists;

      view = playlists.map((item) => {
        const { id, name = '', contents_ids = [], cover_images = [] } = item || {};
        const totalVideos =
          contents_ids.length === 1 ? `${contents_ids.length} Video` : `${contents_ids.length} Videos`;
        const imageObj = cover_images[0] || {};

        return (
          <div className="GP-Item" onClick={() => onPlaylistClick(id)} key={id} data-id={id}>
            <div className="GP-ImageContainer">
              {clickedPlaylist === id && (
                <div className="GP-Loading">
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
              )}

              <ImageLoaderComponent url={imageObj.url} alt={imageObj.alt} noImageText="Playlist is empty" />
              {currentPlaylistId === id && (
                <div className="GP-NowPlaying">
                  <span>now playing</span>
                </div>
              )}
            </div>

            <div className="GP-Info">
              <div className="GP-TitleContainer">
                <h3 className="GP-Title">{name}</h3>
                <div className="GP-Popup">
                  <i
                    className="GP-CogIcon fa fa-cog"
                    onClick={(e) => {
                      e.stopPropagation();
                      setActivePopup((prevState) => ({ [id]: !prevState[id] }));
                    }}
                  ></i>
                  {renderPlaylistsPopup(id)}
                </div>
              </div>
              <span className="GP-Total">{contents_ids.length !== 0 && totalVideos}</span>
            </div>
          </div>
        );
      });
    }

    return <div className="GP-Inner">{view}</div>;
  };

  const renderHeader = () => {
    if (!title) return null;

    return (
      <BaseSectionHeader primaryText={title}>
        <CreateNewPlaylistsBtn refProps={playlistsPlayerPopupRef} updateParentState={updateParentState} />
      </BaseSectionHeader>
    );
  };

  return (
    <div className="GridPlaylist">
      {renderHeader()}
      {renderGrid()}
    </div>
  );
};

GridPlaylist.displayName = 'GridPlaylist';

GridPlaylist.propTypes = {
  allPlaylists: PropTypes.array,
  title: PropTypes.string,
  loading: PropTypes.bool,
  currentPlaylistId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loadingItems: PropTypes.number,
  sliceList: PropTypes.number,
  updateParentState: PropTypes.func,
  onPlaylistClickProps: PropTypes.func,
};

export default GridPlaylist;
