// import { createPortal } from 'react-dom';
import React, { useEffect, useState } from 'react';
import { Link, navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import useBanners from '../../../../hook/useBanners';
import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';

import ImageLoaderComponent from '../../../../components/ImageLoaderComponent/ImageLoaderComponent';
import ImageLoadingSkeleton from '../../../../components/ImageLoadingSkeleton/ImageLoadingSkeleton';
import PageIndicator from '../../../../components/PageIndicator/PageIndicator';
// import AlphabeticNavigation from "../../../../components/AlphabeticNavigation/AlphabeticNavigation";
import ShowMeSortByNavigation from '../../../../components/ShowMeSortByNavigation/ShowMeSortByNavigation';
import SlickSlider from '../../../../components/SlickSlider/SlickSlider';

import { isWeb, removeSpecialCharacters } from '../../../../services/util-service/util.service';

import { getStarScenesRoute } from '../../../../services/navigation/navigation.service.routes';
import { getExclusiveImage } from '../../../../services/images-service/images.service';
// import {
// 	showStarLetterListPage
// } from "../../../../services/navigation/navigation.service";
import { getStarsFeed, getStarsFeedFiltered } from '../../../../services/stars/stars.service';

import './StarsMainView.scss';

const SORT_BY_NEWEST_MOVIE = 'newest_movie';

const StarsMainView = (props) => {
  const { t } = useTranslation();
  const starsBanners = useBanners('stars', 1);
  const [exclusive, setExclusive] = useState([]);
  const [data, setData] = useState({
    page: 1,
    pageCount: undefined,
    perPage: undefined,
    stars: [],
    sortBy: SORT_BY_NEWEST_MOVIE,
    total: undefined,
    letter: '',
    pagination: {
      current_page: 1,
      last_page: 1,
    },
  });
  const [error, setError] = useState(false);
  const { letter, sortBy } = data;

  useEffect(() => {
    if (props.exclusiveStarsEnabled) {
      getStarsFeed(1, SORT_BY_NEWEST_MOVIE, true)
        .then((response) => {
          const data = response?.data?.data;
          if (data.length !== 0) {
            setExclusive(data.stars.slice(0, 8));
          } else {
            console.log('No data found!');
          }
        })
        .catch((err) => console.error(err));
    }
  }, [props.exclusiveStarsEnabled]);

  useEffect(() => {
    const getStarsFeedConfiguration = (page) => {
      return {
        page,
        per_page: 16,
        sort_by: sortBy,
        ...(!!letter && { name_starts_with: letter }),
        // exclusive: false,
        with_custom_images: 1,
        star_images_custom_only: 1,
        // star_images_master_only: 0
      };
    };

    const fetchStarsFeed = (current_page) => {
      getStarsFeedFiltered(getStarsFeedConfiguration(current_page))
        .then((res) => {
          const { stars, pagination = {} } = res?.data?.data || {};
          const { current_page: page, last_page: pageCount, per_page, total } = pagination;
          setData((prev) => ({
            ...prev,
            stars: stars || prev.stars,
            dataLoaded: true,
            pagination: pagination,
            page,
            pageCount,
            perPage: Number(per_page),
            total,
          }));
        })
        .catch((err) => {
          setError(true);
          console.error(err);
        });
    };

    fetchStarsFeed(data.pagination.current_page);
  }, [data.pagination.current_page, sortBy, letter]);

  const getShowMeNavigationItems = () => {
    return {
      items: groupLetters(),
      initialValue: '',
    };
  };

  const groupLetters = () => {
    const groupedLetters = [];
    const { letters } = props;
    const symbols = {
      name: '#',
      value: [],
    };
    const regex = new RegExp(/[A-Za-z]/);
    for (const letter of letters) {
      if (regex.test(letter)) {
        groupedLetters.push(mapLetter(letter));
      } else {
        symbols.value.push(letter);
      }
    }
    if (symbols.value.length) {
      symbols.value = 'misc';
      groupedLetters.splice(0, 0, symbols);
    }

    return groupedLetters;
  };

  const getSortByNavigationItems = () => {
    const { sortBy } = data;
    return {
      items: [
        {
          name: t('StarsMainView.latest'),
          value: SORT_BY_NEWEST_MOVIE,
        },
        {
          name: t('StarsMainView.mostPopular'),
          value: 'most_watched',
        },
        {
          name: t('StarsMainView.az'),
          value: 'a-z',
        },
      ],
      initialValue: sortBy,
    };
  };

  const matchAspectRatio = ({ height, width }) => {
    if (!isNumber(width) || width === 0) {
      return false;
    }
    if (!isNumber(height)) {
      return false;
    }
    return height / width > 1.3;
  };

  const getStarImageUrl = (images) => {
    let url;
    if (images) {
      let image;
      let mediumImage = [];

      const coverImages = images
        .filter((img) => !!img.is_cover)
        .map((img) => {
          if (isSmallView() && img.url.includes('_s.')) {
            mediumImage.push(img);
          } else if (!isSmallView() && img.url.includes('_m.')) {
            mediumImage.push(img);
          }
          return img;
        });

      image = mediumImage.length !== 0 ? mediumImage[0] : coverImages[0];

      if (!image) {
        image = images.find(matchGalleryImage);
      }

      if (!image) {
        image = images.find(matchAspectRatio);
      }
      url = image ? image?.url : images[0]?.url;
    }
    return url;
  };

  const isFirstPage = () => {
    const { page } = data;
    return page && page === 1;
  };

  const isSmallView = () => !isWeb();

  const mapLetter = (letter) => {
    return {
      name: letter,
      value: letter,
    };
  };

  const matchGalleryImage = ({ type }) => type === 'Headshot (Medium)';

  // const onLetterChange = (letter) => {
  // 	const { dataLoaded } = data;
  // 	if (dataLoaded) {
  // 		showStarLetterListPage(letter);
  // 	}
  // };

  const onPageChange = (page) => {
    setData((prev) => ({
      ...prev,
      stars: [],
      dataLoaded: false,
      page,
      pagination: { ...prev.pagination, current_page: page },
    }));
  };

  const onChangeStarLetter = (letter) => {
    if (letter === 'misc') return;
    navigate(`/stars/index/${letter}`);
  };

  const onSortByClick = (sortBy) => {
    setData((prev) => ({
      ...prev,
      stars: [],
      dataLoaded: false,
      pagination: {
        ...prev.pagination,
        current_page: 1,
      },
      sortBy,
    }));
  };

  const renderFakePlaceholders = (itemsCount) => {
    const placeholders = [];
    for (let i = 0; i < itemsCount; i++) {
      placeholders.push(<ImageLoadingSkeleton className="StarImagePlaceholder" key={i} />);
    }
    return placeholders;
  };

  const renderImagesTable = () => {
    const { dataLoaded, stars, page, pageCount, perPage, total } = data;
    let pageItemsCount = 12;
    if (page === pageCount) {
      pageItemsCount = total - (page - 1) * perPage;
    }

    let items = [];
    if (isSmallView()) {
      items.push(...stars.map(renderImageElement));
      if (!dataLoaded) {
        items.push(...renderFakePlaceholders(pageItemsCount));
      }
    } else {
      const itemsCount = !isFirstPage() ? 32 : 16;
      items = dataLoaded ? stars.map(renderImageElement) : renderFakePlaceholders(itemsCount);
    }
    return items;
  };

  const getDestinationUrl = (id, name) => getStarScenesRoute(id, removeSpecialCharacters(name), 1, 'Newest');

  const mapExclusive = ({ id, name, images }) => {
    const { url } = getExclusiveImage(images);

    return (
      <Link key={id} className="ExclusiveStar" to={getDestinationUrl(id, name)}>
        <ImageLoaderComponent url={url} alt={name} width="431" height="655" />
        <h2>{name || 'Star'}</h2>
      </Link>
    );
  };

  const renderImageElement = (item, index) => {
    const { images, name, id } = item;
    const url = getStarImageUrl(images) || '';
    return (
      <Link className="Image-Container" key={`image-number-${index}`} to={getDestinationUrl(id, name)}>
        <ImageLoaderComponent url={url} alt={name} width="431" height="655" />
        <div className="Image-Text">
          <span className="Text">{name}</span>
        </div>
      </Link>
    );
  };

  const renderHeaderSlider = () => {
    if (isFirstPage()) {
      return <SlickSlider slides={starsBanners} />;
    }
  };

  const renderMainDescription = () => {
    const { exclusiveStarsEnabled } = props;
    return exclusiveStarsEnabled && isFirstPage() ? (
      <div className="Met-Our-Stars-Section Section">
        <div className="Met-Our-Stars-Text">
          <div className="Met-Our-Stars-Title">{t('StarsMainView.meetOurStars')}</div>
          <div className="Description">{t('StarsMainView.meetOurStarsText')}</div>
        </div>
      </div>
    ) : null;
  };

  const renderMainNavigation = () => {
    const { letters } = props;
    let view = null;
    if (letters.length) {
      const { initialValue, items } = getShowMeNavigationItems();
      const { initialValue: sortByInitialValue, items: sortByItems } = getSortByNavigationItems();
      view = (
        <ShowMeSortByNavigation
          hideShowMeSeparators={true}
          showMeItems={items}
          showMeInitialValue={initialValue}
          onShowMeClick={onChangeStarLetter}
          sortByItems={sortByItems}
          sortByInitialValue={sortByInitialValue}
          onSortByClick={onSortByClick}
        />
      );
    }
    return view;
  };

  // const renderNavigationButton = () => {
  // 	if (isWeb()) return null;
  // 	const view = (
  // 		<AlphabeticNavigation
  // 			onLetterChange={onLetterChange.bind(this)}
  // 			addSeparator={false}
  // 			activeLetter={""}
  // 			showPartialAlphabet={true}
  // 		/>
  // 	);
  // 	return createPortal(view, document.getElementById('secondaryNavigation'));
  // };

  const renderOurStars = () => {
    return isFirstPage() ? null : <div className="OurStars">{t('StarsMainView.ourStars')}</div>;
  };

  const renderPagination = () => {
    const { pagination } = data;
    return (
      <div className="PageNavigationContainer">
        <PageIndicator page={pagination?.current_page} pageCount={pagination?.last_page} onPageChange={onPageChange} />
      </div>
    );
  };

  const renderStars = () => {
    return (
      <div className="Stars-Images-Section Section">
        <div className="Images-Table">{renderImagesTable()}</div>
      </div>
    );
  };

  const renderExclusiveStars = () => {
    return exclusive?.length && isFirstPage() ? (
      <div className="ExclusiveStars">{exclusive.map(mapExclusive)}</div>
    ) : null;
  };

  const classes = ['StarsMainView'];

  return (
    <div className={classes.join(' ')}>
      {renderHeaderSlider()}
      <div className="StarsMainView-inner">
        {renderMainDescription()}
        {renderExclusiveStars()}
        <div className="StarsView-Sections">
          {renderOurStars()}
          {!error && (
            <>
              {renderMainNavigation()}
              {renderStars()}
              {renderPagination()}
            </>
          )}
        </div>
      </div>
      {/* {renderNavigationButton()} */}
    </div>
  );
};

StarsMainView.propTypes = {
  letters: PropTypes.array,
  exclusiveStarsEnabled: PropTypes.number,
};

export default StarsMainView;
