import React, { useRef, useEffect } from 'react';

import BoxCoverPlayerDetailsComponent from '../../../components/BoxCoverPlayerDetailsComponent/BoxCoverPlayerDetailsComponent';
import PageIndicator from '../../../components/PageIndicator/PageIndicator';
import MoviesScenesGrid from '../../../components/MoviesScenesGrid/MoviesScenesGrid';
import SlickSlider from '../../../components/SlickSlider/SlickSlider';

import { navigationOptions } from '../../../constants/navigation';
import useBanners from '../../../hook/useBanners';
import useMoviesScenesData from '../../../hook/useMoviesScenesData';

import './SeriesPage.scss';

const SeriesPage = (props) => {
  const { seriesId, location = {} } = props;
  const seriesBanners = useBanners('originalsMiddle', 1);
  const videoIdRef = useRef(null);

  const {
    videos,
    pagination: { page, last_page },
    sort,
    type,
    loading,
    error,
    onPageChange,
    onTypeChange,
    onSortByClick,
  } = useMoviesScenesData({
    location,
    hookParams: { series_id: seriesId },
  });

  const firstMovieInTheList = videos[0] || {};
  const isScene = type === navigationOptions.scenes;
  const videoId = isScene ? firstMovieInTheList?.movie?.id : firstMovieInTheList?.id;

  useEffect(() => {
    // avoid rerender the player on filter/page change
    if (videoIdRef.current) return;
    videoIdRef.current = videoId;
  }, [videoId]);

  const renderPlayer = () => {
    if (!videoIdRef.current) return null;
    return <BoxCoverPlayerDetailsComponent movieId={videoIdRef.current} />;
  };

  const renderPagination = () => {
    if (page === 1 && last_page === 1) return null;
    if (error) return null;
    return (
      <PageIndicator
        page={page}
        pageCount={last_page}
        onPageChange={onPageChange}
        scrollIntoView=".ShowMeSortByNavigation"
      />
    );
  };

  return (
    <div className="SeriesPage">
      <h3 className="SerieTitle">{`${firstMovieInTheList?.title || 'Nakedsword'} Series`}</h3>

      {renderPlayer()}

      <MoviesScenesGrid
        data={videos}
        loading={loading}
        type={type}
        sort={sort}
        onShowMeClick={onTypeChange}
        onSortByClick={onSortByClick}
        error={error}
        title={`more ${firstMovieInTheList?.title || ''} ${type}`}
      />

      {renderPagination()}
      <SlickSlider slides={seriesBanners} />
    </div>
  );
};

SeriesPage.displayName = 'SeriesPage';

export default SeriesPage;
