import React from 'react';

import SinglePlayer from '../../SinglePlayer/SinglePlayer';

export default function FreeRideOrDieBonusVideo() {
  return (
    <>
      <SinglePlayer
        src='https://d34kds0xkdmtq8.cloudfront.net/RSS190_SM.mp4'
        poster='https://spotlight.nakedcdn.com/nakedsword/img/free/rideordie/videoposterimage-rideordiebonus.jpg'
      />

      <h4 style={{
        fontSize: '120%',
        margin: '1em 0'
      }}>
        RIDE OR DIE: BEHIND BARS :: RIDE ON IT MUSIC VIDEO
      </h4>

      <p>
        Exclusive Andre Donovan delivers a fiery performance as rap star X-Ray, not to mention a hot original
        single. For the first time, see the music video for ‘RIDE ON IT’, and then download the song from your
        favorite media platform.
      </p>
    </>
  )
};