import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import ImageLoaderComponent from '../../ImageLoaderComponent/ImageLoaderComponent';
import PlaylistsPlayerPopup, { PLAYLIST_ACTION } from '../PlaylistsPlayerPopup/PlaylistsPlayerPopup';

import { useWLconfigContext } from '../../../context/WLConfigContext';

import { removeMovieSceneFromPlaylist } from '../../../services/playlists-service/playlists.service';
import { displayInfoNotification } from '../../../services/notification-service/notification.service';
import { isWeb } from '../../../services/util-service/util.service';

import './PlaylistCarousel.scss';

const DIRECTION = {
  left: 'left',
  right: 'right',
  forward: 'forward',
  backward: 'backward',
};

const PlaylistCarousel = (props) => {
  const {
    currentPlaylist = {},
    videosList = [],
    allPlaylists = [],
    loading,
    nextPageLoading,
    totalVideos,
    getVideoData,
    shouldPlayRandomVideo,
    updateVideosPlaylistState,
    updateUserPlaylistPageState,
    updateFavoritesPageState,
    onChangeIndex,
    currentVideoIndex,
    hidePlaylistControls,
    reorderFn,
    hideEditBtns,
  } = props;
  const { id: playlistId, name: playlistName } = currentPlaylist;
  const { wl_playerConfig: { progressBarColors = {} } = {} } = useWLconfigContext();

  const [state, setState] = useState({
    reorderLoading: false,
    reorderUpdate: new Date().getTime(),
    accordionOpen: true,
    editCurrentPlaylist: false,
  });

  const { reorderLoading, reorderUpdate, accordionOpen, editCurrentPlaylist } = state;

  const sliderRef = useRef(null);
  const totalMovies = totalVideos || videosList?.length;
  const slidesToShow = 8;

  const memeContentsIds = useMemo(() => videosList, [videosList]);
  useEffect(() => {
    if (!isWeb() || hidePlaylistControls) return;

    // DRAG/DROP functionality
    if (sliderRef.current) {
      const slides = document.querySelectorAll('.PC-List .PC-ListItem');

      // https://code-boxx.com/drag-drop-sortable-list-javascript/
      let current = null;

      slides.forEach((item) => {
        item.ondragstart = () => {
          current = item;
          item.style.opacity = '.5';
        };
        item.ondragend = () => {
          item.style.opacity = '';
        };
        item.ondragenter = () => {
          if (item !== current) {
            item.style.backgroundColor = 'rgba(255,255,255, .1)';
          }
        };
        item.ondragleave = () => (item.style.backgroundColor = '');
        item.ondragover = (ev) => ev.preventDefault();
        item.ondrop = (ev) => {
          ev.preventDefault();

          try {
            if (item !== current) {
              let currentPos = 0,
                droppedPos = 0;

              for (let y = 0; y < slides.length; y++) {
                if (current === slides[y]) currentPos = y;
                if (item === slides[y]) droppedPos = y;
              }

              let a1, a2;

              if (currentPos < droppedPos) {
                a1 = item.dataset.reorderId;
                a2 = current.dataset.reorderId;
              } else {
                a1 = current.dataset.reorderId;
                a2 = item.dataset.reorderId;
              }

              const postReorderData = [];
              const prevVideos = [...memeContentsIds];
              const startIndex = prevVideos.findIndex((vid) => +vid.item_id === +a1);
              const endIndex = prevVideos.findIndex((vid) => +vid.item_id === +a2);

              if (currentPos < droppedPos) {
                prevVideos.splice(startIndex, 0, prevVideos.splice(endIndex, 1)[0]);
                postReorderData.push({
                  item_id: prevVideos[droppedPos].item_id,
                  put_before_item_id: prevVideos[droppedPos + 1]?.item_id || null,
                });
              } else {
                prevVideos.splice(endIndex, 0, prevVideos.splice(startIndex, 1)[0]);
                postReorderData.push({ item_id: +a1, put_before_item_id: +a2 });
              }

              // on reorder update VideosPlaylist.js
              if (updateVideosPlaylistState) {
                updateVideosPlaylistState((prevState) => ({
                  ...prevState,
                  videosList: prevVideos,
                }));
              }

              // on reorder update FavoritesPage.js
              if (updateFavoritesPageState) {
                updateFavoritesPageState((prevState) => ({
                  ...prevState,
                  videos: prevVideos,
                }));
              }

              // on reorder update UserPlaylistsPage.js (click on new item will change order back)
              if (updateUserPlaylistPageState) {
                updateUserPlaylistPageState((prevState) => ({
                  ...prevState,
                  currentPlaylist: {
                    ...prevState.currentPlaylist,
                    contents_ids: prevVideos,
                  },
                }));
              }

              // update VideosPlaylist.js current index on drop
              if (onChangeIndex) {
                if (currentVideoIndex === currentPos) {
                  onChangeIndex(droppedPos);
                }
              }

              setState((prevState) => ({
                ...prevState,
                reorderUpdate: new Date().getTime(), // rerender to get latest order
                reorderLoading: true,
              }));

              if (reorderFn) {
                reorderFn(playlistId, postReorderData)
                  .catch((err) => console.log(err))
                  .finally(() => {
                    setState((prevState) => ({
                      ...prevState,
                      reorderLoading: false,
                    }));
                  });
              }
            }
          } catch (err) {
            console.error('UserPlaylistsPage', err);
          }
        };
      });
    }
  }, [
    loading,
    memeContentsIds,
    playlistId,
    updateVideosPlaylistState,
    updateUserPlaylistPageState,
    updateFavoritesPageState,
    onChangeIndex,
    currentVideoIndex,
    accordionOpen,
    reorderUpdate,
    hidePlaylistControls,
    reorderFn,
  ]);

  useEffect(() => {
    // close edit playlist popup on playlist change, and/or when close accordion
    setState((prevState) => ({ ...prevState, editCurrentPlaylist: false }));
  }, [loading, reorderLoading, accordionOpen]);

  useEffect(() => {
    return () => {
      setState({});
    };
  }, []);

  const toggleAccordion = () => {
    setState((prevState) => ({
      ...prevState,
      accordionOpen: !prevState.accordionOpen,
    }));
  };

  const scrollSlideContainer = ({ direction }) => {
    if (!sliderRef.current) return;

    try {
      const container = sliderRef.current;
      const slideWidth = sliderRef.current.querySelector('.PC-ListItem').offsetWidth;

      const adjustSlideWidth = () => {
        // <li> is moving to right so every n times adjust position
        if (currentVideoIndex !== 0 && currentVideoIndex % slidesToShow === 0) {
          if (direction === DIRECTION.right) {
            return slideWidth + 5;
          }
        }
        return slideWidth;
      };

      const slideItemScroll = adjustSlideWidth(); // slidesToShow; // 8 slides visible

      const smoothScroll = (dir, width) => {
        // let scrollAmount = 0;

        // const intervalID = setInterval(() => {
        if (dir === DIRECTION.right) {
          if (currentVideoIndex < totalMovies - 1) {
            container.scrollLeft += width;
          } else {
            container.scrollLeft = 0; // scroll to start
          }
        } else if (dir === DIRECTION.left) {
          if (currentVideoIndex > 0) {
            container.scrollLeft -= width;
          } else {
            container.scrollLeft = container.scrollWidth; // scroll to end
          }
        } else if (dir === DIRECTION.forward) {
          container.scrollLeft += width * 8;
        } else if (dir === DIRECTION.backward) {
          container.scrollLeft -= width * 8;
        }

        //     scrollAmount += width;
        //     if (scrollAmount >= adjustSlideWidth()) {
        //         clearInterval(intervalID);
        //     }
        // }, 40);
      };

      if (direction === DIRECTION.right) {
        smoothScroll(DIRECTION.right, slideItemScroll);
      } else if (direction === DIRECTION.left) {
        smoothScroll(DIRECTION.left, slideItemScroll);
      } else if (direction === DIRECTION.forward) {
        smoothScroll(DIRECTION.forward, slideItemScroll);
      } else if (direction === DIRECTION.backward) {
        smoothScroll(DIRECTION.backward, slideItemScroll);
      }
    } catch (err) {
      console.error('scrollSlideContainer', err);
    }
  };

  const onPrevNextClick = (dir) => {
    if (!onChangeIndex) return;

    let index = currentVideoIndex;

    if (dir === DIRECTION.right) {
      let slidesLength = totalMovies - 1;
      if (index === slidesLength) index = -1;
      ++index;
      onChangeIndex(index);
      scrollSlideContainer({ direction: DIRECTION.right });
    } else {
      if (index === 0) return;
      --index;
      onChangeIndex(index);
      scrollSlideContainer({ direction: DIRECTION.left });
    }
  };

  const onFastForwardBackward = (dir) => {
    if (!onChangeIndex) return;

    let index = currentVideoIndex;

    if (dir === DIRECTION.forward) {
      if (currentVideoIndex + slidesToShow > totalMovies - 1) return;
      scrollSlideContainer({ direction: DIRECTION.forward });

      if (index >= totalMovies) {
        index = totalMovies;
      } else {
        index += slidesToShow;
      }
    } else {
      if (currentVideoIndex < slidesToShow) return;
      scrollSlideContainer({ direction: DIRECTION.backward });

      if (index <= 0) {
        index = 0;
      } else {
        index -= slidesToShow;
      }
    }
    onChangeIndex(index);
  };

  const onPlayRandomVideo = () => {
    if (updateVideosPlaylistState) {
      updateVideosPlaylistState((prevState) => ({
        ...prevState,
        shouldPlayRandomVideo: !prevState.shouldPlayRandomVideo,
      }));
    }
  };

  const removeVideoFromPlaylist = ({ sceneId, movieId }) => {
    if (sceneId) movieId = undefined;

    const params = {
      playlistId,
      movieId,
      sceneId,
    };

    setState((prevState) => ({ ...prevState, reorderLoading: true }));

    removeMovieSceneFromPlaylist({ ...params })
      .then(() => {
        displayInfoNotification({
          message: 'Remove from playlist success!',
          duration: 3,
        });

        const updateVideos = videosList.filter((item) => {
          if (sceneId) {
            return item.id !== sceneId;
          }
          return item.id !== movieId;
        });

        const updateAllPlaylists = allPlaylists.map((item) => {
          if (item.id === playlistId) {
            item.contents_ids.pop();

            if (updateVideos.length === 0) {
              item.cover_images = [];
            }
          }
          return item;
        });

        // update videos in VideosPlaylist.js
        if (updateVideosPlaylistState) {
          updateVideosPlaylistState((prevState) => ({
            ...prevState,
            videosList: updateVideos,
            currentVideoIndex: 0,
          }));
        }

        // update number of videos in UserPlaylistsPage.js -> GridPlaylist.js
        if (updateUserPlaylistPageState) {
          updateUserPlaylistPageState((prevState) => ({
            ...prevState,
            currentPlaylist: {
              ...prevState.currentPlaylist,
              contents_ids: updateVideos,
            },
            allPlaylists: updateAllPlaylists,
          }));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setState((prevState) => ({ ...prevState, reorderLoading: false }));
      });
  };

  const renderPrevNextButtons = (dir) => {
    const onClickFn = () => (dir === DIRECTION.right ? onPrevNextClick(DIRECTION.right) : onPrevNextClick());
    const className = dir === DIRECTION.right ? `Right ${nextPageLoading ? 'NextPageLoadingBtn' : ''}` : 'Left';

    return <button className={`PC-DirectionButtons ${className}`} onClick={() => onClickFn()}></button>;
  };

  const renderCarouselItem = (item, index) => {
    if (!getVideoData) return null;

    const { item_id, videoMovieId, videoSceneId, videoName, videoImage, videoDuration } = getVideoData(item);
    const videoId = videoSceneId || videoMovieId;

    return (
      <li
        className="PC-ListItem"
        key={videoId + '-' + index}
        draggable={hidePlaylistControls ? false : true}
        onClick={() => onChangeIndex(index)}
        data-reorder-id={item_id}
      >
        <div className="PC-ImageContaner">
          <ImageLoaderComponent url={videoImage.url} alt={videoImage.alt} />
          <div className="PC-Duration">{videoDuration}</div>
          {currentVideoIndex === index && (
            <div className="PC-NowPlaying">
              <span>now playing</span>
            </div>
          )}
        </div>

        <div className="PC-VideoTitle">
          <span>{videoName}</span>
          {!hideEditBtns && (
            <i
              title="Remove from list"
              className="fa fa-times cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                removeVideoFromPlaylist({
                  sceneId: videoSceneId,
                  movieId: videoMovieId,
                });
              }}
            ></i>
          )}
        </div>
      </li>
    );
  };

  const onEditCurrentPlaylist = () => {
    setState((prevState) => ({
      ...prevState,
      editCurrentPlaylist: !prevState.editCurrentPlaylist,
    }));
  };

  const renderEditCurrentPlaylistPopup = () => {
    if (!editCurrentPlaylist) return null;

    return (
      <PlaylistsPlayerPopup
        title="Update playlist"
        closePopup={onEditCurrentPlaylist}
        callback={({ name, action }) => {
          onEditCurrentPlaylist();

          if (updateUserPlaylistPageState) {
            if (action === PLAYLIST_ACTION.update) {
              // update UI
              const updateAllPlaylists = allPlaylists.map((item) => {
                if (item.id === playlistId) {
                  item.name = name;
                }
                return item;
              });

              // update name and number of videos in UserPlaylistsPage.js -> GridPlaylist.js
              updateUserPlaylistPageState((prevState) => ({
                ...prevState,
                currentPlaylist: {
                  ...prevState.currentPlaylist,
                  name,
                },
                allPlaylists: updateAllPlaylists,
              }));
            }
          }
        }}
        selectedPlaylist={currentPlaylist}
        hideList
        update
      />
    );
  };

  const renderView = () => {
    const randomVideoActiveColor = shouldPlayRandomVideo ? { color: progressBarColors.primary } : {};
    const totalVideosText = totalMovies === 1 ? `${totalMovies} Video` : `${totalMovies} Videos`;

    const view = () => {
      return (
        <>
          {(loading || reorderLoading) && (
            <div className="PC-ReorderLoader">
              <i className="fa fa-spinner fa-spin"></i>
            </div>
          )}
          {renderPrevNextButtons()}
          <ul className="PC-List" ref={sliderRef}>
            {videosList.map((item, index) => renderCarouselItem(item, index))}
          </ul>
          {renderPrevNextButtons(DIRECTION.right)}
          {nextPageLoading && <i className="fa fa-spinner fa-spin NextPageLoadingIcon"></i>}
        </>
      );
    };

    const renderTotal = () => {
      return (
        <span className="PC-Total">
          {totalMovies !== 0 ? currentVideoIndex + 1 : 0} / {totalVideosText}
        </span>
      );
    };

    return (
      <>
        <div className="PC-AccordionOptions">
          <div className="PC-CurrentPlaylist">
            <span>{playlistName}</span>
            {isWeb() && renderTotal()}
          </div>
          <div className="PC-IconsContainer">
            {!isWeb() && renderTotal()}

            <div className="PC-Icons">
              <i className="fa fa-fast-backward" onClick={() => onFastForwardBackward()}></i>
              <i className="fa fa-fast-forward" onClick={() => onFastForwardBackward(DIRECTION.forward)}></i>
              <i
                style={randomVideoActiveColor}
                className="fa fa-random JSRandomIcon"
                data-active={shouldPlayRandomVideo ? 1 : 0}
                title="Play random video"
                onClick={() => onPlayRandomVideo()}
              ></i>
              {!hideEditBtns && (
                <i className="PC-CogIcon fa fa-cog" title="Edit playlist" onClick={() => onEditCurrentPlaylist()}></i>
              )}
              <div className="PC-Popup">{renderEditCurrentPlaylistPopup()}</div>
              <i className={`fa fa-caret-${accordionOpen ? 'up' : 'down'} Caret`} onClick={() => toggleAccordion()}></i>
            </div>
          </div>
        </div>

        {accordionOpen && <div className="PC-ListContainer">{view()}</div>}
      </>
    );
  };

  if (videosList.length === 0 && !loading) return null;

  return <div className="PlaylistCarousel">{renderView()}</div>;
};

PlaylistCarousel.displayName = 'PlaylistCarousel';

PlaylistCarousel.propTypes = {
  currentPlaylist: PropTypes.object,
  videosList: PropTypes.array,
  allPlaylists: PropTypes.array,
  loading: PropTypes.bool,
  nextPageLoading: PropTypes.bool,
  hidePlaylistControls: PropTypes.bool,
  shouldPlayRandomVideo: PropTypes.bool,
  hideEditBtns: PropTypes.bool,
  getVideoData: PropTypes.func,
  updateFavoritesPageState: PropTypes.func,
  reorderFn: PropTypes.func,
  updateVideosPlaylistState: PropTypes.func,
  updateUserPlaylistPageState: PropTypes.func,
  onChangeIndex: PropTypes.func,
  currentVideoIndex: PropTypes.number,
  totalVideos: PropTypes.number,
};

export default PlaylistCarousel;
