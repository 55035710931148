import React from 'react';
import BannerSetViewFunc from '../../../components/BannerSetView/BannerSetViewFunc.jsx';
import { useHtmlContentContext } from '../../../context/HtmlContentContext.js';
import RenderHtmlContent from '../../../components/RenderHtmlContent/RenderHtmlContent.js';
import F4FLive from './F4FLive.js';
import { isWeb } from '../../../services/util-service/util.service.js';
import StreamateLink from '../../../components/StreamateLink/StreamateLink.js';

const Live = () => {
  const { htmlContent } = useHtmlContentContext();

  return (
    <>
      <h2 style={{
        color: '#112d5d',
        fontSize: '170%',
        margin: '1em 0 0',
        textTransform: 'uppercase',
        textAlign: 'center'
      }}>Chat with the hottest men online now!</h2>

      {isWeb() ? (
        <F4FLive iframeSrc='https://camspacelive.com/widgets/live.php?cta=gtr&style=expandable-theme001-0001&mp_code=b2ew&service=guys&language=en&use_promo=0&continuous_play=1&model_id=&bgcolor=FFFFFF&txtcolor=FFFFFF&linkcolor=FFFFFF&num_models=50&sitekey=whitelabel&whitelabel_domain=nakedswordlive.com&target=_blank&btncolor=333333&btntxtcolor=CCCCCC&accentcolor=515151' />
      ) : (
        <F4FLive iframeSrc='https://camspacelive.com/widgets/live.php?cta=gtr&style=320x320-theme001-jscroll-l2r-0002&mp_code=b2ew&service=guys&language=en&use_promo=0&continuous_play=1&model_id=&bgcolor=FFFFFF&txtcolor=FFFFFF&linkcolor=FFFFFF&num_models=20&sitekey=whitelabel&whitelabel_domain=nakedswordlive.com&target=_blank&btncolor=333333&btntxtcolor=CCCCCC&accentcolor=515151' />
      )}

      {isWeb() ? (
        <StreamateLink
          iframeSrc='https://as.sexad.net/as/if?p=reseller&w=1&h=1&v=9933&adHeight=950&adWidth=890&niche=gay&adType=live&autoplay=false&showvideo=false&hn=nakedswordcasting.com&AFNO=1-1'
        />
      ) : (
        <StreamateLink
          iframeSrc='https://as.sexad.net/as/if?p=reseller&w=1&h=1&v=5104&adHeight=350&adWidth=400&niche=gay&adType=live&autoplay=true&showvideo=true&hn=nakedswordcasting.com&AFNO=1-1'
        />
      )}
      <RenderHtmlContent htmlContent={htmlContent} dataLabel="support_service" />
      <BannerSetViewFunc bannerName="tour" />
    </>
  );
};

Live.displayName = 'Live';

export default Live;
