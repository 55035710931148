import React from 'react';
import { useLocation } from '@reach/router';

const NewsletterConfirm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email'); // Retrieve the email from query parameters

  return (
    <div className="NewsletterConfirm" style={{ textAlign: 'center', padding: '1em .5em' }}>
      <h1>Thank You for Subscribing!</h1>
      <p>
        We sent an email to <strong>{email}</strong> in order to finalize the subscription process.
        If you cannot find this email, please check your junk or spam folders.
      </p>
    </div>
  );
};

export default NewsletterConfirm;