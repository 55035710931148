import React from 'react';

import SinglePlayer from '../../SinglePlayer/SinglePlayer';

export default function FreeRideOrDieBonusMeetTheWarden() {
  return (
    <>
      <SinglePlayer
        src='https://d34kds0xkdmtq8.cloudfront.net/RSS190_SJ.mp4'
        poster='https://spotlight.nakedcdn.com/nakedsword/img/free/rideordie/videoposterimage-coleconnor.jpg'
      />

      <h4 style={{
        fontSize: '120%',
        margin: '1em 0'
      }}>
        RIDE OR DIE: BEHIND BARS :: MEET THE WARDEN
      </h4>

      <p>
        Exclusive Cole Connor takes fans inside the mind 'RIDE OR DIE's diabolical warden, offering both a
        no-holds-barred look at his acting process and all-new footage from the production.
      </p>
    </>
  )
};