import React, { useState, useEffect, useCallback } from 'react';

import GridPlaylist from '../../../components/PlaylistComponents/GridPlaylist/GridPlaylist';
import { ErrorMessage } from '../../../components/ErrorBoundary/ErrorBoundary';
import VideosPlaylist from '../../VideosPlaylist/VideosPlaylist';

import {
  getAllPlaylists,
  getSinglePlaylist,
  reorderPlaylistItems,
} from '../../../services/playlists-service/playlists.service';
import { showApplicationStart } from '../../../services/navigation/navigation.service';
import { useAuthContext } from '../../../context/AuthContext';

import './UserPlaylistsPage.scss';

const UserPlaylistsPage = (props) => {
  const { location = {} } = props;
  const singlePlaylistId = location.state?.playlistId; // from My Staff page
  const { userLoggedIn } = useAuthContext();

  const [state, setState] = useState({
    allPlaylists: [],
    currentPlaylist: {
      id: '',
      name: '',
      contents_ids: [],
      playlistLoading: true,
    },
    loading: true,
    error: false,
  });

  const { allPlaylists = [], currentPlaylist = {}, loading, error } = state;

  const fetchSinglePlaylist = useCallback(
    (id) => {
      const playlistId = id || singlePlaylistId;

      if (!playlistId) return;

      setState((prevState) => ({
        ...prevState,
        currentPlaylist: {
          ...prevState.currentPlaylist,
          playlistLoading: true,
        },
      }));

      getSinglePlaylist(playlistId)
        .then((resp = {}) => {
          const singlePlaylist = resp.data?.data || {};

          setState((prevState) => ({
            ...prevState,
            currentPlaylist: {
              ...prevState.currentPlaylist,
              id: +singlePlaylist.id || '',
              name: singlePlaylist.name || '',
              contents_ids: singlePlaylist.contents || [],
            },
          }));
        })
        .catch((err) => {
          setState((prevState) => ({ ...prevState, error: true }));
          console.log(err);
        })
        .finally(() => {
          setState((prevState) => ({
            ...prevState,
            currentPlaylist: {
              ...prevState.currentPlaylist,
              playlistLoading: false,
            },
          }));
        });
    },
    [singlePlaylistId],
  );

  useEffect(() => {
    fetchSinglePlaylist();
  }, [fetchSinglePlaylist]);

  useEffect(() => {
    if (!userLoggedIn) {
      showApplicationStart();
      return;
    };

    setState((prevState) => ({
      ...prevState,
      allPlaylists: [],
      loading: true,
    }));

    getAllPlaylists()
      .then((resp = {}) => {
        const allPlaylists = resp.data?.data || [];
        setState((prevState) => ({ ...prevState, allPlaylists }));
      })
      .catch((err) => {
        setState((prevState) => ({ ...prevState, error: true }));
        console.log(err);
      })
      .finally(() => setState((prevState) => ({ ...prevState, loading: false })));
  }, [userLoggedIn]);

  if (error) return <ErrorMessage />;

  const renderView = () => {
    return (
      <div className="UserPlaylistsPage">
        <VideosPlaylist
          videos={currentPlaylist.contents_ids}
          loading={currentPlaylist.playlistLoading}
          allPlaylists={allPlaylists}
          currentPlaylist={{
            id: currentPlaylist.id,
            name: currentPlaylist.name,
          }}
          updateUserPlaylistPageState={setState}
          reorderFn={reorderPlaylistItems}
        />

        <GridPlaylist
          allPlaylists={allPlaylists}
          loading={loading}
          updateParentState={setState}
          onPlaylistClickProps={fetchSinglePlaylist}
          title="All playlists"
          currentPlaylistId={currentPlaylist.id}
        />
      </div>
    );
  };

  return userLoggedIn ? renderView() : null;
};

UserPlaylistsPage.displayName = 'UserPlaylistsPage';

export default UserPlaylistsPage;
