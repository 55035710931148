import React from 'react';
import { Link } from '@reach/router';

import { ROUTES } from '../../../services/navigation/navigation.routes';
import { useHtmlContentContext } from '../../../context/HtmlContentContext.js';
import RenderHtmlContent from '../../../components/RenderHtmlContent/RenderHtmlContent.js';

const AvsError = () => {
  const { htmlContent } = useHtmlContentContext();

  return (
    <>
      <div className="Content">
        <div className="Heading">AGE VERIFICATION ERROR</div>

        <p>
          Sorry we encountered an error during your verification. Please
          <Link className="Link" to={`/${ROUTES.ageVerificationLanding}`}>
            {' '}
            try again
          </Link>
          .
        </p>
      </div>
      <RenderHtmlContent htmlContent={htmlContent} dataLabel="support_service" />
    </>
  );
};

AvsError.displayName = 'AvsError';

export default AvsError;
