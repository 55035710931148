import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import BoxCoverPlayerDetailsComponent from '../../components/BoxCoverPlayerDetailsComponent/BoxCoverPlayerDetailsComponent';
import PlaylistCarousel from '../../components/PlaylistComponents/PlaylistCarousel/PlaylistCarousel';

import { convertSecondsToTime } from '../../services/util-service/date.service';

import './VideosPlaylist.scss';

const VideosPlaylist = (props) => {
  const {
    updateUserPlaylistPageState,
    updateFavoritesPageState,
    currentPlaylist = {},
    allPlaylists = [],
    videos = [],
    loading,
    page,
    lastPage,
    totalVideos,
    onPageChange,
    nextPageLoading,
    hidePlaylistControls,
    hideEditBtns,
    reorderFn,
  } = props;

  const [state, setState] = useState({
    videosList: [],
    currentVideo: {},
    currentVideoIndex: 0,
    shouldPlayRandomVideo: false,
  });

  const { videosList = [], currentVideo = {}, currentVideoIndex, shouldPlayRandomVideo } = state;

  const playerRef = useRef(null);

  // on playlist item click update current video and fetch player data
  useEffect(() => {
    if (videos.length === 0) return; // prevents infinite loop

    setState((prevState) => ({
      ...prevState,
      videosList: videos,
      currentVideo: videos[prevState.currentVideoIndex],
    }));
  }, [currentVideoIndex, loading, videos]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      videosList: [],
      currentVideoIndex: 0,
    }));

    return () => {
      playerRef.current = null;
    };
  }, [currentPlaylist.id]);

  const onChangeIndex = (index) => {
    const playerElem = document.querySelector('.video-js');
    if (playerElem) {
      try {
        playerRef.current.pause();
        playerElem.classList.add('vjs-waiting');
      } catch (e) {
        console.log('playerElem.classList.add', e);
      }
    }

    if (onPageChange) {
      if (nextPageLoading) {
        if (index < videosList.length - 1) {
          setState((prevState) => ({ ...prevState, currentVideoIndex: index }));
        }
        return;
      }

      if (index > videosList.length - 5) {
        if (page < lastPage) {
          onPageChange(page + 1);
        }
      }
    }
    setState((prevState) => ({ ...prevState, currentVideoIndex: index }));
  };

  const getPlayer = (playerId, VideoPlayController) => {
    const { player, fullPlayer = {} } = VideoPlayController.getVideoPlayer(playerId) || {};

    if (player) {
      playerRef.current = player;
      const playedVideos = [];

      player.on('timeupdate', () => {
        // 'ended' event not working for movies
        const duration = Math.trunc(player.duration());
        const currentTime = Math.trunc(player.currentTime());

        if (duration === currentTime) {
          // get random video functionality - play next video when video ends
          const playingVideoId = fullPlayer.props?.data?.videos?.[0]?.id; // id comes from BoxCoverPlayerDetailsComponent.js

          const playingVideoIndex = videosList.findIndex((v) => v?.id === playingVideoId);

          playedVideos.push(playingVideoId);

          const randomIcon = document.querySelector('.JSRandomIcon');
          let isRandomActive = 0;

          if (randomIcon) isRandomActive = +randomIcon.dataset.active;

          if (isRandomActive) {
            // filter out played videos and find random not played video
            const nonPlayedIds = videosList.filter((video) => !playedVideos.includes(video?.item_id));
            const randomItemId = nonPlayedIds[Math.floor(Math.random() * nonPlayedIds.length)]?.item_id;
            const randomIndex = videosList.findIndex((item) => item?.item_id === randomItemId);

            if (!randomItemId) {
              // when all videos were played
              setState((prevState) => ({
                ...prevState,
                shouldPlayRandomVideo: false,
              }));
              player.pause();
            } else {
              onChangeIndex(randomIndex);
            }
          } else {
            const nextIndex = playingVideoIndex < videosList.length - 1 ? playingVideoIndex + 1 : 0;
            onChangeIndex(nextIndex);
          }
        }
      });
    }
  };

  const getVideoData = (data = {}) => {
    const {
      title,
      titleNs,
      first_scene = {},
      cover_images = [],
      streaming_movie = {},
      movie = {},
      startTimeSeconds,
      endTimeSeconds,
      runTime,
      id,
      movieId,
      studios = [],
      categories = [],
      stars = [],
      description,
      descriptionNs,
      type,
      item_id, // for reorder
    } = data;
    let videoMovieId = movieId || movie.movieId;
    let videoSceneId = movieId ? undefined : id;
    let videoName = titleNs ? titleNs : title;
    let videoImage = first_scene.cover_images?.[0] || {};
    let videoDuration = runTime;
    let videoDescription = descriptionNs ? descriptionNs : description;
    let videoStartTime = startTimeSeconds;
    let videoEndTime = endTimeSeconds;
    let episodesId = undefined;

    if (streaming_movie && Object.keys(streaming_movie).length !== 0) {
      const { id, title, titleNs } = streaming_movie;
      videoName = titleNs ? titleNs : title;
      videoImage = cover_images?.[0] || {};
      videoDuration = convertSecondsToTime(videoEndTime - videoStartTime);
      episodesId = videoMovieId !== id ? id : undefined;
    }

    if (type === 'movie') {
      videoStartTime = undefined;
      videoEndTime = undefined;
    }

    return {
      item_id,
      videoMovieId,
      videoSceneId,
      episodesId,
      videoName,
      videoImage,
      videoDuration,
      videoDescription,
      studios,
      categories,
      stars,
      videoStartTime,
      videoEndTime,
    };
  };

  const renderCarousel = () => {
    if (videosList.length === 0) return null;

    return (
      <PlaylistCarousel
        videosList={videosList}
        totalVideos={totalVideos}
        allPlaylists={allPlaylists}
        currentPlaylist={currentPlaylist}
        loading={loading}
        nextPageLoading={nextPageLoading}
        getVideoData={getVideoData}
        onChangeIndex={onChangeIndex}
        currentVideoIndex={currentVideoIndex}
        updateVideosPlaylistState={setState}
        hideEditBtns={hideEditBtns}
        updateUserPlaylistPageState={updateUserPlaylistPageState}
        updateFavoritesPageState={updateFavoritesPageState}
        shouldPlayRandomVideo={shouldPlayRandomVideo}
        hidePlaylistControls={hidePlaylistControls}
        reorderFn={reorderFn}
      />
    );
  };

  const renderVideoData = () => {
    if (videosList.length === 0) return null;

    const { videoMovieId, videoSceneId } = getVideoData(currentVideo);

    if (!videoMovieId && !videoSceneId) {
      return null;
    }

    return (
      <BoxCoverPlayerDetailsComponent
        movieId={videoMovieId}
        sceneId={videoSceneId}
        omitAds
        addVideoToHistory
        getPlayer={getPlayer}
        playlistPage
      />
    );
  };

  const renderView = () => {
    return (
      <div className="VideosPlaylist">
        {renderCarousel()}
        {renderVideoData()}
      </div>
    );
  };

  return renderView();
};

VideosPlaylist.displayName = 'VideosPlaylist';

VideosPlaylist.propTypes = {
  videos: PropTypes.array,
  allPlaylists: PropTypes.array,
  loading: PropTypes.bool,
  updateUserPlaylistPageState: PropTypes.func,
  updateFavoritesPageState: PropTypes.func,
  currentPlaylist: PropTypes.object,
  page: PropTypes.number,
  lastPage: PropTypes.number,
  totalVideos: PropTypes.number,
  nextPageLoading: PropTypes.bool,
  onPageChange: PropTypes.func,
  reorderFn: PropTypes.func,
  hidePlaylistControls: PropTypes.bool,
  hideEditBtns: PropTypes.bool,
};

export default VideosPlaylist;
