import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import BoxCoverPlayerDetailsComponent from '../../../components/BoxCoverPlayerDetailsComponent/BoxCoverPlayerDetailsComponent';
import MovieScenes from './MovieScenes/MovieScenes';
import MoviesScenesGrid from '../../../components/MoviesScenesGrid/MoviesScenesGrid';
import BannerSetViewFunc from '../../../components/BannerSetView/BannerSetViewFunc';

import './MovieDetailsPage.scss';

const MovieDetailsPage = (props) => {
  const { movieId, sceneIndex } = props;

  const { t } = useTranslation();

  const [state, setState] = useState({
    movie: {
      loading: true,
      scenes: [],
      recommendations: [],
    },
  });

  const { movie } = state;

  const renderMainView = () => {
    return (
      <>
        {renderMovieDetails()}
        <MoviesScenesGrid
          title={t('MovieRecommendations.youMightAlsoLike')}
          data={movie.recommendations}
          loading={movie.loading}
          error={movie.error}
          hideNavigation
        />
      </>
    );
  };

  const renderMovieDetails = () => {
    return (
      <>
        {movieId && <BoxCoverPlayerDetailsComponent movieId={movieId} updateMovieDetailsPage={setState} />}
        <MovieScenes scenes={movie.scenes} sceneIndex={sceneIndex} />
      </>
    );
  };

  return (
    <div className="MovieDetailsPage">
      <BannerSetViewFunc bannerName="promo" />
      {renderMainView()}
      <BannerSetViewFunc bannerName="tour" />
    </div>
  );
};

MovieDetailsPage.propTypes = {
  movieId: PropTypes.string,
  sceneIndex: PropTypes.string,
};

export default MovieDetailsPage;
