import React, { useEffect, useState } from 'react';
import useBanners from '../../../hook/useBanners';

import BannerSetViewFunc from '../../../components/BannerSetView/BannerSetViewFunc';
import AlphabeticView from '../../../components/AlphabeticView/AlphabeticView';
import SlickSlider from '../../../components/SlickSlider/SlickSlider';

import { getStudios } from '../../../services/properties/properties.service';
import { getStudioDetailsRoute } from '../../../services/navigation/navigation.service.routes';
import { removeSpecialCharacters } from '../../../services/util-service/util.service';
import { ErrorMessage } from '../../../components/ErrorBoundary/ErrorBoundary';
import './StudiosPage.scss';

const StudiosPage = () => {
  const studiosBanners = useBanners('studios', 1);
  const [content, setContent] = useState({
    studios: {
      alphabet: [],
      items: {},
      loaded: false,
    },
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    const loadData = (response) => {
      const items = response.data.data;
      setContent({
        studios: {
          loaded: true,
          items,
          alphabet: Object.keys(items),
        },
      });
    };

    getStudios()
      .then(loadData)
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  }, []);

  const parseItem = ({ name, id }) => {
    return {
      id,
      name,
      goTo: getStudioDetailsRoute(id, removeSpecialCharacters(name)),
    };
  };

  if (error) return <ErrorMessage />;

  return (
    <div className="StudiosPage">
      <BannerSetViewFunc bannerName="promo" />
      <SlickSlider slides={studiosBanners} />
      <AlphabeticView
        activeSection={'studios'}
        data={content}
        translationKey={'StudioAlphabetDisplay'}
        parseItem={parseItem}
      />
      <BannerSetViewFunc bannerName="tour" />
    </div>
  );
};

StudiosPage.displayName = 'StudiosPage';

export default StudiosPage;
