import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './AlphabeticNavigation.scss';

class AlphabeticNavigation extends Component {
  lettersRef = React.createRef();

  state = {
    activeLetter: undefined,
    alphabet: [],
  };

  constructor(props) {
    super(props);
    this.state.activeLetter = props.activeLetter;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = null;
    if (!prevState.activeLetter !== nextProps.activeLetter) {
      state = {
        activeLetter: nextProps.activeLetter,
      };
    }

    return state;
  }

  componentDidMount() {
    this.setState({
      alphabet: this.generateAlphabet(),
    });
  }

  alphabetReducer = (result, letter, index, array) => {
    result.push(this.mapAlphabetLetter(letter));
    if (index < array.length - 1) {
      result.push(<div className="AlphabeticNavigation-separator" key={`${letter.name || letter}-${index}`} />);
    }

    return result;
  };

  generateAlphabet = () => {
    const alphabet = [
      {
        name: '#',
        value: 'misc',
      },
    ];
    const start = 'A'.charCodeAt(0);
    const last = 'Z'.charCodeAt(0);
    let letter;
    for (let i = start; i <= last; ++i) {
      letter = String.fromCharCode(i);
      alphabet.push(letter);
    }

    return alphabet;
  };

  mapAlphabetLetter = (letter) => {
    const { activeLetter } = this.state;
    const classes = ['Letter'];
    const name = letter.name || letter;
    const isHashTag = name === '#';
    const isActiveLetter = isHashTag ? activeLetter === letter.value : activeLetter === name;
    const additionalData = isActiveLetter
      ? {}
      : {
          onClick: this.onLetterClick.bind(this, letter),
        };
    if (isActiveLetter) {
      classes.push('Active');
    }
    return (
      <div className={classes.join(' ')} key={name} {...additionalData}>
        {name}
      </div>
    );
  };

  onLetterClick = (activeLetter) => {
    const name = activeLetter.name || activeLetter;
    this.setState({ activeLetter: name });
    const value = activeLetter.value || activeLetter;
    this.props.onLetterChange(value);
  };

  renderLetters = () => {
    let { alphabet } = this.state;
    const { additionalCharacters, addSeparator, overridingCharacters, showPartialAlphabet } = this.props;
    if (overridingCharacters) {
      alphabet = overridingCharacters;
    } else if (additionalCharacters) {
      alphabet = alphabet.slice(); // Create a copy of alphabet so we don't update state reference
      alphabet.splice(0, 0, ...additionalCharacters);
    }
    if (showPartialAlphabet) {
      alphabet = alphabet.slice(); // Create a copy of alphabet so we don't update state reference
    }
    let view;
    if (addSeparator) {
      view = alphabet.reduce(this.alphabetReducer, []);
    } else {
      view = alphabet.map(this.mapAlphabetLetter);
    }

    return view;
  };

  scroll = (scrollRight) => {
    const { current } = this.lettersRef;
    const { scrollLeft: currentScrollLeft, scrollWidth } = current;
    let scrollLeft;
    if (scrollRight) {
      scrollLeft = currentScrollLeft + 50;
      if (scrollLeft > scrollWidth) {
        scrollLeft = scrollWidth;
      }
    } else {
      scrollLeft = currentScrollLeft - 50;
      if (!scrollLeft) {
        scrollLeft = 0;
      }
    }
    current.scrollLeft = scrollLeft;
  };

  render() {
    const { showPartialAlphabet, mobileScroll } = this.props;
    let classes = ['AlphabeticNavigation'];
    if (showPartialAlphabet) {
      classes.push('PartialAlphabet');
    }
    if (mobileScroll) {
      classes.push('MobileScroll');
    }

    return (
      <div className={classes.join(' ')}>
        {mobileScroll && (
          <div className="EndMarker" onClick={this.scroll.bind(this, false)}>
            <i className="fas fa-angle-left" />
          </div>
        )}
        <div className="Letters" ref={this.lettersRef}>
          {this.renderLetters()}
        </div>
        {mobileScroll && (
          <div className="EndMarker" onClick={this.scroll.bind(this, true)}>
            <i className="fas fa-angle-right" />
          </div>
        )}
      </div>
    );
  }
}

AlphabeticNavigation.propTypes = {
  activeLetter: PropTypes.string,
  additionalCharacters: PropTypes.array,
  addSeparator: PropTypes.bool,
  mobileScroll: PropTypes.bool,
  onLetterChange: PropTypes.func.isRequired,
  overridingCharacters: PropTypes.array,
};

export default AlphabeticNavigation;
