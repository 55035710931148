import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { showApplicationStart } from '../../services/navigation/navigation.service';

import NewToday from '../MainDashBoard/MainDashboardView/NewToday/NewToday';

import './NotFound.scss';

const NotFound = (props) => {
  const { t } = useTranslation();

  const renderIcon = () => {
    const { iconClass, iconSrc } = props;
    return iconSrc ? <img src={iconSrc} alt="Icon" /> : <i className={iconClass || 'far fa-question-circle'} />;
  };

  const { linkWrapperClass, subtitleRowOne, subtitleRowTwo, title } = props;
  return (
    <div className="NotFound">
      <div className="NotFound-top" onClick={showApplicationStart}>
        <div className={linkWrapperClass}>{renderIcon()}</div>
        <div className="NotFound-section">
          <div className="Leading">{title || t('NotFound.hmm')}</div>
          <div className="Following">{subtitleRowOne || t('NotFound.weCantFind')}</div>
          <div className="Following">{subtitleRowTwo || t('NotFound.clickHere')}</div>
        </div>
      </div>
      <NewToday />
    </div>
  );
};

NotFound.propTypes = {
  iconClass: PropTypes.string,
  iconSrc: PropTypes.string,
  linkWrapperClass: PropTypes.string,
  subtitleRowOne: PropTypes.string,
  subtitleRowTwo: PropTypes.string,
  title: PropTypes.string,
};

export default NotFound;
