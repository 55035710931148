import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './MyAccountSectionHeader.scss';

class MyAccountSectionHeader extends Component {
  state = {};

  getActionStateKey = (index) => {
    return `action${index}Expanded`;
  };

  onActionClick = (isExpandable, onClick, index) => {
    if (isExpandable) {
      this.setState((prevState) => {
        const key = this.getActionStateKey(index);
        const expanded = !prevState[key];
        onClick(expanded);
        return {
          [key]: expanded,
        };
      });
    } else {
      onClick();
    }
  };

  renderAction = ({ isExpandable, text, onClick, allowed }, index) => {
    return allowed ? (
      <div
        className="Action"
        key={`action-${index}`}
        onClick={this.onActionClick.bind(this, isExpandable, onClick, index)}
      >
        {isExpandable ? this.renderExpander(index) : null}
        <div className="ActionText">{text}</div>
      </div>
    ) : null;
  };

  renderActions = () => {
    const { actions } = this.props;
    return <div className="Actions">{actions.map(this.renderAction)}</div>;
  };

  renderExpander = (index) => {
    const key = this.getActionStateKey(index);
    const expanded = this.state[key];
    const className = expanded ? 'fa-minus' : 'fa-plus';
    return (
      <div className="Expander">
        <i className={`fas ${className}`} />
      </div>
    );
  };

  renderTitle = () => {
    const { title } = this.props;
    return <div className="Title">{title}</div>;
  };

  render() {
    return (
      <div className="MyAccountSectionHeader">
        {this.renderTitle()}
        {this.renderActions()}
      </div>
    );
  }
}

MyAccountSectionHeader.propTypes = {
  actions: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default MyAccountSectionHeader;
