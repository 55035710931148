import React from 'react';
import WarningLogo from '../../../images/Warning_Gold.svg';
import { useTranslation } from 'react-i18next';
import { useWLconfigContext } from '../../../context/WLConfigContext';

import { showJoinNowPage } from '../../../services/navigation/navigation.service';
import NewToday from '../MainDashboardView/NewToday/NewToday';

import './StreamBlocked.scss';

const StreamBlocked = () => {
  const { t } = useTranslation();
  const {
    variables: {
      REACT_APP_SUPPORT_EMAIL,
      REACT_APP_BILLING_SUPPORT_SITE
    } = {}
  } = useWLconfigContext();

  const renderIcon = () => {
    return <img src={WarningLogo} alt="Icon" />;
  };

  const getTelephone = (telKey) => {
    const telephone = t(`common:${telKey}`);
    return (
      <a className="Text Link" href={`tel:${telephone.replace(/\./g, '')}`} target={'_self'}>
        {telephone}
      </a>
    );
  };

  const renderEpoch = (url) => {
    return url ? (
      <>
        <div className="Text">&nbsp;or visit EPOCH&nbsp;</div>
        <a className="Text Link" href={url} target={'_self'}>
          {url?.replace('https://', '')}
        </a>
      </>
    ) : null;
  };

  return (
    <div className="StreamBlocked">
      <div className="Wrapper">
        <div className="StreamBlocked-top">
          <div className={'Logo'}>{renderIcon()}</div>
          <div className="Information">
            <div className="Title">{t('StreamBlocked.title')}</div>
            <div className="Following">{t('StreamBlocked.message')}</div>
          </div>
        </div>
        <div className="Content">
          <div className="Text Padding">{t('StreamBlocked.contact')}</div>
          <div className="Text Bold Uppercase">{t('StreamBlocked.customerSupport')}</div>
          <div className="Row">
            <div className="Text">
              {t('common:tollFree')}&nbsp;
              {getTelephone('localSupportServiceCallNumber')}
            </div>
            <div className="Separator"></div>
            <div className="Text">
              {t('common:international')}&nbsp;
              {getTelephone('internationalSupportServiceCallNumber')}
            </div>
          </div>
          <div className="Row Padding">
            <div className="Text">NakedSword&nbsp;</div>
            <a className="Text Link" href={`mailto: ${REACT_APP_SUPPORT_EMAIL}`} target={'_self'}>
              {REACT_APP_SUPPORT_EMAIL}
            </a>
            {renderEpoch(REACT_APP_BILLING_SUPPORT_SITE)}
          </div>
          <div className="Text">{t('StreamBlocked.available')}</div>
          <div className="Row">
            <div className="Text">{t('SupportService.workingHours')}</div>
            <div className="Separator"></div>
            <div className="Text">{t('SupportService.mondayFriday')}</div>
          </div>
          <div className="Text">{t('StreamBlocked.closed')}</div>

          <div className="Row Padding">
            <div className="Text Uppercase">{t('StreamBlocked.dontHaveAccount')}</div>
            <div className="Separator None"></div>
            <div className="Text Uppercase Bold Link" onClick={showJoinNowPage}>
              {t('StreamBlocked.signUp')}
            </div>
          </div>
        </div>
      </div>
      <NewToday />
    </div>
  );
};

export default StreamBlocked;
